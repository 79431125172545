@charset "UTF-8";
.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Open Sans;
}
.mat-badge-small .mat-badge-content {
  font-size: 9px;
}
.mat-badge-large .mat-badge-content {
  font-size: 24px;
}
.mat-h1,
.mat-headline,
.mat-typography .mat-h1,
.mat-typography .mat-headline,
.mat-typography h1 {
  //font: 400 24px/32px Open Sans;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.mat-h2,
.mat-title,
.mat-typography .mat-h2,
.mat-typography .mat-title,
.mat-typography h2 {
  //font: 500 20px/32px Open Sans;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.mat-h3,
.mat-subheading-2,
.mat-typography .mat-h3,
.mat-typography .mat-subheading-2,
.mat-typography h3 {
  //font: 400 16px/28px Open Sans;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.mat-h4,
.mat-subheading-1,
.mat-typography .mat-h4,
.mat-typography .mat-subheading-1,
.mat-typography h4 {
  //font: 400 15px/24px Open Sans;
  letter-spacing: normal;
  margin: 0 0 8px;
}
.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  //font: 400 11.62px/20px Open Sans;
  margin: 0 0 12px;
}
.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  //font: 400 9.38px/20px Open Sans;
  margin: 0 0 12px;
}
.mat-body-strong,
.mat-body-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-body-2 {
  //font: 500 14px/24px Open Sans;
  letter-spacing: normal;
}
.mat-body,
.mat-body-1,
.mat-typography .mat-body,
.mat-typography .mat-body-1,
.mat-typography {
  //font: 400 14px/20px Open Sans;
  letter-spacing: normal;
}
.mat-body p,
.mat-body-1 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-1 p,
.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: 400 12px/20px Open Sans;
  letter-spacing: normal;
}
.mat-display-4,
.mat-typography .mat-display-4 {
  font: 300 112px/112px Open Sans;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}
.mat-display-3,
.mat-typography .mat-display-3 {
  font: 400 56px/56px Open Sans;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}
.mat-display-2,
.mat-typography .mat-display-2 {
  font: 400 45px/48px Open Sans;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}
.mat-display-1,
.mat-typography .mat-display-1 {
  font: 400 34px/40px Open Sans;
  letter-spacing: normal;
  margin: 0 0 64px;
}
.mat-bottom-sheet-container {
  font: 400 14px/20px Open Sans;
  letter-spacing: normal;
}
.mat-button,
.mat-raised-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button,
.mat-fab,
.mat-mini-fab {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 500;
}
.mat-button-toggle,
.mat-card {
  font-family: Open Sans;
}
.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}
.mat-card-header .mat-card-title {
  font-size: 20px;
}
.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}
.mat-checkbox {
  font-family: Open Sans;
}
.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}
.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}
.mat-table {
  font-family: Open Sans;
}
.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}
.mat-cell,
.mat-footer-cell {
  font-size: 14px;
}
.mat-calendar {
  font-family: Open Sans;
}
.mat-calendar-body {
  font-size: 13px;
}
.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}
.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}
.mat-dialog-title {
  font: 500 20px/32px Open Sans;
  letter-spacing: normal;
}
.mat-expansion-panel-header {
  font-family: Open Sans;
  font-size: 15px;
  font-weight: 400;
}
.mat-expansion-panel-content {
  font: 400 14px/20px Open Sans;
  letter-spacing: normal;
}
.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Open Sans;
  letter-spacing: normal;
}
.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}
.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}
.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}
.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float
  .mat-input-server:focus
  + .mat-form-field-label-wrapper
  .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float
  .mat-input-server[label]:not(:label-shown)
  + .mat-form-field-label-wrapper
  .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}
.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}
.mat-form-field-label {
  top: 1.34375em;
}
.mat-form-field-underline {
  bottom: 1.34375em;
}
.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}
.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float
  .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float
  .mat-input-server:focus
  + .mat-form-field-label-wrapper
  .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px)
    translateZ(0.001px);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float
  .mat-form-field-autofill-control:-webkit-autofill
  + .mat-form-field-label-wrapper
  .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px)
    translateZ(0.00101px);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float
  .mat-input-server[label]:not(:label-shown)
  + .mat-form-field-label-wrapper
  .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px)
    translateZ(0.00102px);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}
@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float
    .mat-form-field-label,
  .mat-form-field-appearance-legacy.mat-form-field-can-float
    .mat-input-server:focus
    + .mat-form-field-label-wrapper
    .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float
    .mat-form-field-autofill-control:-webkit-autofill
    + .mat-form-field-label-wrapper
    .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float
    .mat-input-server[label]:not(:label-shown)
    + .mat-form-field-label-wrapper
    .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float
  .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float
  .mat-input-server:focus
  + .mat-form-field-label-wrapper
  .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float
  .mat-input-server[label]:not(:label-shown)
  + .mat-form-field-label-wrapper
  .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}
.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float
  .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float
  .mat-input-server:focus
  + .mat-form-field-label-wrapper
  .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float
  .mat-input-server[label]:not(:label-shown)
  + .mat-form-field-label-wrapper
  .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}
.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n + 2),
.mat-grid-tile-footer .mat-line:nth-child(n + 2) {
  font-size: 12px;
}
input.mat-input-element {
  margin-top: -0.0625em;
}
.mat-menu-item {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
}
.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Open Sans;
  font-size: 12px;
}
.mat-radio-button,
.mat-select {
  font-family: Open Sans;
}
.mat-select-trigger {
  height: 1.125em;
}
.mat-slide-toggle-content {
  font-family: Open Sans;
}
.mat-slider-thumb-label-text {
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 500;
}
.mat-stepper-vertical,
.mat-stepper-horizontal {
  font-family: Open Sans;
}
.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}
.mat-step-sub-label-error {
  font-weight: 400;
}
.mat-step-label-error {
  font-size: 14px;
}
.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}
.mat-tab-group {
  font-family: Open Sans;
}
.mat-tab-label,
.mat-tab-link {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 500;
}
.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  //font: 500 20px/32px Open Sans;
  letter-spacing: normal;
  margin: 0;
}
.mat-tooltip {
  font-family: Open Sans;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-list-item,
.mat-list-option {
  font-family: Open Sans;
}
.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n + 2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n + 2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 500;
}
.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n + 2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n + 2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 500;
}
.mat-option {
  font-family: Open Sans;
  font-size: 16px;
}
.mat-optgroup-label {
  font: 500 14px/24px Open Sans;
  letter-spacing: normal;
}
.mat-simple-snackbar {
  font-family: Open Sans;
  font-size: 14px;
}
.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}
.mat-tree {
  font-family: Open Sans;
}
.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}
.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}
.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}
.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}
.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir="rtl"] .cdk-visually-hidden {
  left: auto;
  right: 0;
}
.cdk-overlay-container,
.cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.cdk-overlay-container {
  position: fixed;
  z-index: 7;
}
.cdk-overlay-container:empty {
  display: none;
}
.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 7;
}
.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 7;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}
.cdk-overlay-backdrop {
  position: absolute;
  inset: 0;
  z-index: 7;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}
.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}
.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}
.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 7;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}
.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}
textarea.cdk-textarea-autosize {
  resize: none;
}
textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}
textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}
@keyframes cdk-text-field-autofill-start {
}
@keyframes cdk-text-field-autofill-end {
}
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}
.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}
.mat-focus-indicator,
.mat-mdc-focus-indicator {
  position: relative;
}
.mat-ripple-element {
  background-color: #0000001a;
}
.mat-option {
  color: #000000de;
}
.mat-option:hover:not(.mat-option-disabled),
.mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: #000000de;
}
.mat-option.mat-option-disabled {
  color: #00000061;
}
.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #900;
}
.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #5d5760;
}
.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #C00000;
}
.mat-optgroup-label {
  color: #0000008a;
}
.mat-optgroup-disabled .mat-optgroup-label {
  color: #00000061;
}
.mat-pseudo-checkbox {
  color: #0000008a;
}
.mat-pseudo-checkbox:after {
  color: #fafafa;
}
.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #990000;
}
.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #C00000;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.mat-app-background {
  background-color: #fafafa;
  color: #000000de;
}
.mat-elevation-z0 {
  box-shadow: 0 0 #0003, 0 0 #00000024, 0 0 #0000001f;
}
.mat-elevation-z1 {
  box-shadow: 0 2px 1px -1px #0003, 0 1px 1px #00000024, 0 1px 3px #0000001f;
}
.mat-elevation-z2 {
  box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
}
.mat-elevation-z3 {
  box-shadow: 0 3px 3px -2px #0003, 0 3px 4px #00000024, 0 1px 8px #0000001f;
}
.mat-elevation-z4 {
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
}
.mat-elevation-z5 {
  box-shadow: 0 3px 5px -1px #0003, 0 5px 8px #00000024, 0 1px 14px #0000001f;
}
.mat-elevation-z6 {
  box-shadow: 0 3px 5px -1px #0003, 0 6px 10px #00000024, 0 1px 18px #0000001f;
}
.mat-elevation-z7 {
  box-shadow: 0 4px 5px -2px #0003, 0 7px 10px 1px #00000024,
    0 2px 16px 1px #0000001f;
}
.mat-elevation-z8 {
  box-shadow: 0 5px 5px -3px #0003, 0 8px 10px 1px #00000024,
    0 3px 14px 2px #0000001f;
}
.mat-elevation-z9 {
  box-shadow: 0 5px 6px -3px #0003, 0 9px 12px 1px #00000024,
    0 3px 16px 2px #0000001f;
}
.mat-elevation-z10 {
  box-shadow: 0 6px 6px -3px #0003, 0 10px 14px 1px #00000024,
    0 4px 18px 3px #0000001f;
}
.mat-elevation-z11 {
  box-shadow: 0 6px 7px -4px #0003, 0 11px 15px 1px #00000024,
    0 4px 20px 3px #0000001f;
}
.mat-elevation-z12 {
  box-shadow: 0 7px 8px -4px #0003, 0 12px 17px 2px #00000024,
    0 5px 22px 4px #0000001f;
}
.mat-elevation-z13 {
  box-shadow: 0 7px 8px -4px #0003, 0 13px 19px 2px #00000024,
    0 5px 24px 4px #0000001f;
}
.mat-elevation-z14 {
  box-shadow: 0 7px 9px -4px #0003, 0 14px 21px 2px #00000024,
    0 5px 26px 4px #0000001f;
}
.mat-elevation-z15 {
  box-shadow: 0 8px 9px -5px #0003, 0 15px 22px 2px #00000024,
    0 6px 28px 5px #0000001f;
}
.mat-elevation-z16 {
  box-shadow: 0 8px 10px -5px #0003, 0 16px 24px 2px #00000024,
    0 6px 30px 5px #0000001f;
}
.mat-elevation-z17 {
  box-shadow: 0 8px 11px -5px #0003, 0 17px 26px 2px #00000024,
    0 6px 32px 5px #0000001f;
}
.mat-elevation-z18 {
  box-shadow: 0 9px 11px -5px #0003, 0 18px 28px 2px #00000024,
    0 7px 34px 6px #0000001f;
}
.mat-elevation-z19 {
  box-shadow: 0 9px 12px -6px #0003, 0 19px 29px 2px #00000024,
    0 7px 36px 6px #0000001f;
}
.mat-elevation-z20 {
  box-shadow: 0 10px 13px -6px #0003, 0 20px 31px 3px #00000024,
    0 8px 38px 7px #0000001f;
}
.mat-elevation-z21 {
  box-shadow: 0 10px 13px -6px #0003, 0 21px 33px 3px #00000024,
    0 8px 40px 7px #0000001f;
}
.mat-elevation-z22 {
  box-shadow: 0 10px 14px -6px #0003, 0 22px 35px 3px #00000024,
    0 8px 42px 7px #0000001f;
}
.mat-elevation-z23 {
  box-shadow: 0 11px 14px -7px #0003, 0 23px 36px 3px #00000024,
    0 9px 44px 8px #0000001f;
}
.mat-elevation-z24 {
  box-shadow: 0 11px 15px -7px #0003, 0 24px 38px 3px #00000024,
    0 9px 46px 8px #0000001f;
}
.mat-theme-loaded-marker {
  display: none;
}
.mat-autocomplete-panel {
  background: white;
  color: #000000de;
}
.mat-autocomplete-panel:not([class*="mat-elevation-z"]) {
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.mat-autocomplete-panel
  .mat-option.mat-selected:not(.mat-active):not(:hover):not(
    .mat-option-disabled
  ) {
  color: #000000de;
}
.mat-badge {
  position: relative;
}
.mat-badge.mat-badge {
  overflow: visible;
}
.mat-badge-hidden .mat-badge-content {
  display: none;
}
.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 0.2s ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}
.mat-badge-content.mat-badge-active {
  transform: none;
}
.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir="rtl"] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir="rtl"] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir="rtl"]
  .mat-badge-small.mat-badge-overlap.mat-badge-before
  .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir="rtl"]
  .mat-badge-small.mat-badge-overlap.mat-badge-after
  .mat-badge-content {
  right: auto;
  left: -8px;
}
.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir="rtl"] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir="rtl"] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir="rtl"]
  .mat-badge-medium.mat-badge-overlap.mat-badge-before
  .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir="rtl"]
  .mat-badge-medium.mat-badge-overlap.mat-badge-after
  .mat-badge-content {
  right: auto;
  left: -11px;
}
.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir="rtl"] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir="rtl"] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir="rtl"]
  .mat-badge-large.mat-badge-overlap.mat-badge-before
  .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir="rtl"]
  .mat-badge-large.mat-badge-overlap.mat-badge-after
  .mat-badge-content {
  right: auto;
  left: -14px;
}
.mat-badge-content {
  color: #900;
  background: #990000;
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}
.mat-badge-accent .mat-badge-content {
  background: #5d5760;
  color: #d8d4d9;
}
.mat-badge-warn .mat-badge-content {
  color: #900;
  background: #C00000;
}
.mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: #00000061;
}
.mat-bottom-sheet-container {
  box-shadow: 0 8px 10px -5px #0003, 0 16px 24px 2px #00000024,
    0 6px 30px 5px #0000001f;
  background: white;
  color: #000000de;
}
.mat-button,
.mat-icon-button,
.mat-stroked-button {
  color: inherit;
  background: transparent;
}
.mat-button.mat-primary,
.mat-icon-button.mat-primary,
.mat-stroked-button.mat-primary {
  color: #900;
}
.mat-button.mat-accent,
.mat-icon-button.mat-accent,
.mat-stroked-button.mat-accent {
  color: #5d5760;
}
.mat-button.mat-warn,
.mat-icon-button.mat-warn,
.mat-stroked-button.mat-warn {
  color: #C00000;
}
.mat-button.mat-primary.mat-button-disabled,
.mat-button.mat-accent.mat-button-disabled,
.mat-button.mat-warn.mat-button-disabled,
.mat-button.mat-button-disabled.mat-button-disabled,
.mat-icon-button.mat-primary.mat-button-disabled,
.mat-icon-button.mat-accent.mat-button-disabled,
.mat-icon-button.mat-warn.mat-button-disabled,
.mat-icon-button.mat-button-disabled.mat-button-disabled,
.mat-stroked-button.mat-primary.mat-button-disabled,
.mat-stroked-button.mat-accent.mat-button-disabled,
.mat-stroked-button.mat-warn.mat-button-disabled,
.mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: #00000042;
}
.mat-button.mat-primary .mat-button-focus-overlay,
.mat-icon-button.mat-primary .mat-button-focus-overlay,
.mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #900;
}
.mat-button.mat-accent .mat-button-focus-overlay,
.mat-icon-button.mat-accent .mat-button-focus-overlay,
.mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #5d5760;
}
.mat-button.mat-warn .mat-button-focus-overlay,
.mat-icon-button.mat-warn .mat-button-focus-overlay,
.mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #C00000;
}
.mat-button.mat-button-disabled .mat-button-focus-overlay,
.mat-icon-button.mat-button-disabled .mat-button-focus-overlay,
.mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.mat-button .mat-ripple-element,
.mat-icon-button .mat-ripple-element,
.mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.mat-button-focus-overlay {
  background: black;
}
.mat-stroked-button:not(.mat-button-disabled) {
  border-color: #0000001f;
}
.mat-flat-button,
.mat-raised-button,
.mat-fab,
.mat-mini-fab {
  color: #000000de;
  background-color: #fff;
}
.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
  color: #900;
}
.mat-flat-button.mat-accent,
.mat-raised-button.mat-accent,
.mat-fab.mat-accent,
.mat-mini-fab.mat-accent {
  color: #d8d4d9;
}
.mat-flat-button.mat-warn,
.mat-raised-button.mat-warn,
.mat-fab.mat-warn,
.mat-mini-fab.mat-warn {
  color: #900;
}
.mat-flat-button.mat-primary.mat-button-disabled,
.mat-flat-button.mat-accent.mat-button-disabled,
.mat-flat-button.mat-warn.mat-button-disabled,
.mat-flat-button.mat-button-disabled.mat-button-disabled,
.mat-raised-button.mat-primary.mat-button-disabled,
.mat-raised-button.mat-accent.mat-button-disabled,
.mat-raised-button.mat-warn.mat-button-disabled,
.mat-raised-button.mat-button-disabled.mat-button-disabled,
.mat-fab.mat-primary.mat-button-disabled,
.mat-fab.mat-accent.mat-button-disabled,
.mat-fab.mat-warn.mat-button-disabled,
.mat-fab.mat-button-disabled.mat-button-disabled,
.mat-mini-fab.mat-primary.mat-button-disabled,
.mat-mini-fab.mat-accent.mat-button-disabled,
.mat-mini-fab.mat-warn.mat-button-disabled,
.mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: #00000042;
}
.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
  background-color: #900;
}
.mat-flat-button.mat-accent,
.mat-raised-button.mat-accent,
.mat-fab.mat-accent,
.mat-mini-fab.mat-accent {
  background-color: #5d5760;
}
.mat-flat-button.mat-warn,
.mat-raised-button.mat-warn,
.mat-fab.mat-warn,
.mat-mini-fab.mat-warn {
  background-color: #C00000;
}
.mat-flat-button.mat-primary.mat-button-disabled,
.mat-flat-button.mat-accent.mat-button-disabled,
.mat-flat-button.mat-warn.mat-button-disabled,
.mat-flat-button.mat-button-disabled.mat-button-disabled,
.mat-raised-button.mat-primary.mat-button-disabled,
.mat-raised-button.mat-accent.mat-button-disabled,
.mat-raised-button.mat-warn.mat-button-disabled,
.mat-raised-button.mat-button-disabled.mat-button-disabled,
.mat-fab.mat-primary.mat-button-disabled,
.mat-fab.mat-accent.mat-button-disabled,
.mat-fab.mat-warn.mat-button-disabled,
.mat-fab.mat-button-disabled.mat-button-disabled,
.mat-mini-fab.mat-primary.mat-button-disabled,
.mat-mini-fab.mat-accent.mat-button-disabled,
.mat-mini-fab.mat-warn.mat-button-disabled,
.mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: #0000001f;
}
.mat-flat-button.mat-primary .mat-ripple-element,
.mat-raised-button.mat-primary .mat-ripple-element,
.mat-fab.mat-primary .mat-ripple-element,
.mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: #9900001a;
}
.mat-flat-button.mat-accent .mat-ripple-element,
.mat-raised-button.mat-accent .mat-ripple-element,
.mat-fab.mat-accent .mat-ripple-element,
.mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: #d8d4d91a;
}
.mat-flat-button.mat-warn .mat-ripple-element,
.mat-raised-button.mat-warn .mat-ripple-element,
.mat-fab.mat-warn .mat-ripple-element,
.mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: #9900001a;
}
.mat-stroked-button:not([class*="mat-elevation-z"]),
.mat-flat-button:not([class*="mat-elevation-z"]) {
  box-shadow: 0 0 #0003, 0 0 #00000024, 0 0 #0000001f;
}
.mat-raised-button:not([class*="mat-elevation-z"]) {
  box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
}
.mat-raised-button:not(.mat-button-disabled):active:not(
    [class*="mat-elevation-z"]
  ) {
  box-shadow: 0 5px 5px -3px #0003, 0 8px 10px 1px #00000024,
    0 3px 14px 2px #0000001f;
}
.mat-raised-button.mat-button-disabled:not([class*="mat-elevation-z"]) {
  box-shadow: 0 0 #0003, 0 0 #00000024, 0 0 #0000001f;
}
.mat-fab:not([class*="mat-elevation-z"]),
.mat-mini-fab:not([class*="mat-elevation-z"]) {
  box-shadow: 0 3px 5px -1px #0003, 0 6px 10px #00000024, 0 1px 18px #0000001f;
}
.mat-fab:not(.mat-button-disabled):active:not([class*="mat-elevation-z"]),
.mat-mini-fab:not(.mat-button-disabled):active:not([class*="mat-elevation-z"]) {
  box-shadow: 0 7px 8px -4px #0003, 0 12px 17px 2px #00000024,
    0 5px 22px 4px #0000001f;
}
.mat-fab.mat-button-disabled:not([class*="mat-elevation-z"]),
.mat-mini-fab.mat-button-disabled:not([class*="mat-elevation-z"]) {
  box-shadow: 0 0 #0003, 0 0 #00000024, 0 0 #0000001f;
}
.mat-button-toggle-standalone:not([class*="mat-elevation-z"]),
.mat-button-toggle-group:not([class*="mat-elevation-z"]) {
  box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
}
.mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not(
    [class*="mat-elevation-z"]
  ),
.mat-button-toggle-group-appearance-standard:not([class*="mat-elevation-z"]) {
  box-shadow: none;
}
.mat-button-toggle {
  color: #00000061;
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: #0000001f;
}
.mat-button-toggle-appearance-standard {
  color: #000000de;
  background: white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: #000;
}
.mat-button-toggle-group-appearance-standard
  .mat-button-toggle
  + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
[dir="rtl"]
  .mat-button-toggle-group-appearance-standard
  .mat-button-toggle
  + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical
  .mat-button-toggle
  + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: #0000008a;
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: #000000de;
}
.mat-button-toggle-disabled {
  color: #00000042;
  background-color: #eee;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}
.mat-card {
  background: white;
  color: #000000de;
}
.mat-card:not([class*="mat-elevation-z"]) {
  box-shadow: 0 2px 1px -1px #0003, 0 1px 1px #00000024, 0 1px 3px #0000001f;
}
.mat-card.mat-card-flat:not([class*="mat-elevation-z"]) {
  box-shadow: 0 0 #0003, 0 0 #00000024, 0 0 #0000001f;
}
.mat-card-subtitle {
  color: #0000008a;
}
.mat-checkbox-frame {
  border-color: #0000008a;
}
.mat-checkbox-checkmark {
  fill: #fafafa;
}
.mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background,
.mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #900;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #5d5760;
}
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background,
.mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #C00000;
}
.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background,
.mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.mat-checkbox-disabled .mat-checkbox-label {
  color: #00000061;
}
.mat-checkbox .mat-ripple-element {
  background-color: #000;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary
  .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary
  .mat-ripple-element {
  background: #990000;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent
  .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent
  .mat-ripple-element {
  background: #5d5760;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #C00000;
}
.mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: #000000de;
}
.mat-chip.mat-standard-chip .mat-chip-remove {
  color: #000000de;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0 3px 3px -2px #0003, 0 3px 4px #00000024, 0 1px 8px #0000001f;
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.mat-chip.mat-standard-chip:after {
  background: black;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #900;
  color: #900;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: #900;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: #9900001a;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #C00000;
  color: #900;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: #900;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: #9900001a;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #5d5760;
  color: #d8d4d9;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: #d8d4d9;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: #d8d4d91a;
}
.mat-table {
  background: white;
}
.mat-table thead,
.mat-table tbody,
.mat-table tfoot,
mat-header-row,
mat-row,
mat-footer-row,
[mat-header-row],
[mat-row],
[mat-footer-row],
.mat-table-sticky {
  background: inherit;
}
mat-row,
mat-header-row,
mat-footer-row,
th.mat-header-cell,
td.mat-cell,
td.mat-footer-cell {
  border-bottom-color: #0000001f;
}
.mat-header-cell {
  color: #0000008a;
}
.mat-cell,
.mat-footer-cell {
  color: #000000de;
}
.mat-calendar-arrow {
  fill: #0000008a;
}
.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: #0000008a;
}
.mat-calendar-table-header-divider:after {
  background: rgba(0, 0, 0, 0.12);
}
.mat-calendar-table-header,
.mat-calendar-body-label {
  color: #0000008a;
}
.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: #000000de;
  border-color: transparent;
}
.mat-calendar-body-disabled
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ) {
  color: #00000061;
}
.mat-form-field-disabled .mat-date-range-input-separator {
  color: #00000061;
}
.mat-calendar-body-in-preview {
  color: #0000003d;
}
.mat-calendar-body-today:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ) {
  border-color: #00000061;
}
.mat-calendar-body-disabled
  > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ) {
  border-color: #0000002e;
}
.mat-calendar-body-in-range:before {
  background: rgba(153, 0, 0, 0.2);
}
.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range:before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-calendar-body-comparison-bridge-start:before,
[dir="rtl"] .mat-calendar-body-comparison-bridge-end:before {
  background: linear-gradient(
    to right,
    rgba(153, 0, 0, 0.2) 50%,
    rgba(249, 171, 0, 0.2) 50%
  );
}
.mat-calendar-body-comparison-bridge-end:before,
[dir="rtl"] .mat-calendar-body-comparison-bridge-start:before {
  background: linear-gradient(
    to left,
    rgba(153, 0, 0, 0.2) 50%,
    rgba(249, 171, 0, 0.2) 50%
  );
}
.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range:after {
  background: #a8dab5;
}
.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-calendar-body-selected {
  background-color: #900;
  color: #900;
}
.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: #9006;
}
.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px #900;
}
.cdk-keyboard-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ),
.cdk-program-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ) {
  background-color: #9900004d;
}
@media (hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
      .mat-calendar-body-comparison-identical
    ) {
    background-color: #9900004d;
  }
}
.mat-datepicker-content {
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
  background-color: #fff;
  color: #000000de;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range:before {
  background: rgba(93, 87, 96, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent
  .mat-calendar-body-in-comparison-range:before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-accent
  .mat-calendar-body-comparison-bridge-start:before,
.mat-datepicker-content.mat-accent
  [dir="rtl"]
  .mat-calendar-body-comparison-bridge-end:before {
  background: linear-gradient(
    to right,
    rgba(93, 87, 96, 0.2) 50%,
    rgba(249, 171, 0, 0.2) 50%
  );
}
.mat-datepicker-content.mat-accent
  .mat-calendar-body-comparison-bridge-end:before,
.mat-datepicker-content.mat-accent
  [dir="rtl"]
  .mat-calendar-body-comparison-bridge-start:before {
  background: linear-gradient(
    to left,
    rgba(93, 87, 96, 0.2) 50%,
    rgba(249, 171, 0, 0.2) 50%
  );
}
.mat-datepicker-content.mat-accent
  .mat-calendar-body-in-range
  > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent
  .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range:after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-accent
  .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent
  .mat-calendar-body-in-comparison-range
  > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #5d5760;
  color: #d8d4d9;
}
.mat-datepicker-content.mat-accent
  .mat-calendar-body-disabled
  > .mat-calendar-body-selected {
  background-color: #5d576066;
}
.mat-datepicker-content.mat-accent
  .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px #d8d4d9;
}
.mat-datepicker-content.mat-accent
  .cdk-keyboard-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ),
.mat-datepicker-content.mat-accent
  .cdk-program-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ) {
  background-color: #5d57604d;
}
@media (hover: hover) {
  .mat-datepicker-content.mat-accent
    .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
      .mat-calendar-body-comparison-identical
    ) {
    background-color: #5d57604d;
  }
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range:before {
  background: rgba(144, 0, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range:before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-warn
  .mat-calendar-body-comparison-bridge-start:before,
.mat-datepicker-content.mat-warn
  [dir="rtl"]
  .mat-calendar-body-comparison-bridge-end:before {
  background: linear-gradient(
    to right,
    rgba(144, 0, 0, 0.2) 50%,
    rgba(249, 171, 0, 0.2) 50%
  );
}
.mat-datepicker-content.mat-warn
  .mat-calendar-body-comparison-bridge-end:before,
.mat-datepicker-content.mat-warn
  [dir="rtl"]
  .mat-calendar-body-comparison-bridge-start:before {
  background: linear-gradient(
    to left,
    rgba(144, 0, 0, 0.2) 50%,
    rgba(249, 171, 0, 0.2) 50%
  );
}
.mat-datepicker-content.mat-warn
  .mat-calendar-body-in-range
  > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn
  .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range:after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-warn
  .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn
  .mat-calendar-body-in-comparison-range
  > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #C00000;
  color: #900;
}
.mat-datepicker-content.mat-warn
  .mat-calendar-body-disabled
  > .mat-calendar-body-selected {
  background-color: #C0000066;
}
.mat-datepicker-content.mat-warn
  .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px #900;
}
.mat-datepicker-content.mat-warn
  .cdk-keyboard-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ),
.mat-datepicker-content.mat-warn
  .cdk-program-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ) {
  background-color: #C000004d;
}
@media (hover: hover) {
  .mat-datepicker-content.mat-warn
    .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
      .mat-calendar-body-comparison-identical
    ) {
    background-color: #C000004d;
  }
}
.mat-datepicker-content-touch {
  box-shadow: 0 11px 15px -7px #0003, 0 24px 38px 3px #00000024,
    0 9px 46px 8px #0000001f;
}
.mat-datepicker-toggle-active {
  color: #900;
}
.mat-datepicker-toggle-active.mat-accent {
  color: #5d5760;
}
.mat-datepicker-toggle-active.mat-warn {
  color: #C00000;
}
.mat-date-range-input-inner[disabled] {
  color: #00000061;
}
.mat-dialog-container {
  box-shadow: 0 11px 15px -7px #0003, 0 24px 38px 3px #00000024,
    0 9px 46px 8px #0000001f;
  background: white;
  color: #000000de;
}
.mat-divider {
  border-top-color: #0000001f;
}
.mat-divider-vertical {
  border-right-color: #0000001f;
}
.mat-expansion-panel {
  background: white;
  color: #000000de;
}
.mat-expansion-panel:not([class*="mat-elevation-z"]) {
  box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
}
.mat-action-row {
  border-top-color: #0000001f;
}
.mat-expansion-panel
  .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled="true"]),
.mat-expansion-panel
  .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled="true"]),
.mat-expansion-panel:not(.mat-expanded)
  .mat-expansion-panel-header:hover:not([aria-disabled="true"]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled="true"])
    .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: #000000de;
}
.mat-expansion-panel-header-description,
.mat-expansion-indicator:after {
  color: #0000008a;
}
.mat-expansion-panel-header[aria-disabled="true"] {
  color: #00000042;
}
.mat-expansion-panel-header[aria-disabled="true"]
  .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled="true"]
  .mat-expansion-panel-header-description {
  color: inherit;
}
.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}
.mat-form-field-label,
.mat-hint {
  color: #0009;
}
.mat-form-field.mat-focused .mat-form-field-label {
  color: #900;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #5d5760;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #C00000;
}
.mat-focused .mat-form-field-required-marker {
  color: #5d5760;
}
.mat-form-field-ripple {
  background-color: #000000de;
}
.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #900;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #5d5760;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #C00000;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid)
  .mat-form-field-infix:after {
  color: #900;
}
.mat-form-field-type-mat-native-select.mat-focused:not(
    .mat-form-field-invalid
  ).mat-accent
  .mat-form-field-infix:after {
  color: #5d5760;
}
.mat-form-field-type-mat-native-select.mat-focused:not(
    .mat-form-field-invalid
  ).mat-warn
  .mat-form-field-infix:after {
  color: #C00000;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label,
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid
  .mat-form-field-label
  .mat-form-field-required-marker {
  color: #C00000;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #C00000;
}
.mat-error {
  color: #C00000;
}
.mat-form-field-appearance-legacy .mat-form-field-label,
.mat-form-field-appearance-legacy .mat-hint {
  color: #0000008a;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: #0000006b;
}
.mat-form-field-appearance-legacy.mat-form-field-disabled
  .mat-form-field-underline {
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.42) 0%,
    rgba(0, 0, 0, 0.42) 33%,
    transparent 0%
  );
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: #0000006b;
}
.mat-form-field-appearance-standard.mat-form-field-disabled
  .mat-form-field-underline {
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.42) 0%,
    rgba(0, 0, 0, 0.42) 33%,
    transparent 0%
  );
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: #0000000a;
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: #00000005;
}
.mat-form-field-appearance-fill .mat-form-field-underline:before {
  background-color: #0000006b;
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: #00000061;
}
.mat-form-field-appearance-fill.mat-form-field-disabled
  .mat-form-field-underline:before {
  background-color: transparent;
}
.mat-form-field-appearance-outline .mat-form-field-outline {
  color: #0000001f;
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: #000000de;
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #900;
}
.mat-form-field-appearance-outline.mat-focused.mat-accent
  .mat-form-field-outline-thick {
  color: #5d5760;
}
.mat-form-field-appearance-outline.mat-focused.mat-warn
  .mat-form-field-outline-thick,
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid
  .mat-form-field-outline-thick {
  color: #C00000;
}
.mat-form-field-appearance-outline.mat-form-field-disabled
  .mat-form-field-label {
  color: #00000061;
}
.mat-form-field-appearance-outline.mat-form-field-disabled
  .mat-form-field-outline {
  color: #0000000f;
}
.mat-icon.mat-primary {
  color: #900;
}
.mat-icon.mat-accent {
  color: #5d5760;
}
.mat-icon.mat-warn {
  color: #C00000;
}
.mat-form-field-type-mat-native-select .mat-form-field-infix:after {
  color: #0000008a;
}
.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled
  .mat-form-field-infix:after {
  color: #00000061;
}
.mat-input-element {
  caret-color: #900;
}
.mat-input-element::placeholder {
  color: #0000006b;
}
.mat-input-element::-moz-placeholder {
  color: #0000006b;
}
.mat-input-element::-webkit-input-placeholder {
  color: #0000006b;
}
.mat-input-element:-ms-input-placeholder {
  color: #0000006b;
}
.mat-form-field.mat-accent .mat-input-element {
  caret-color: #5d5760;
}
.mat-form-field.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: #C00000;
}
.mat-form-field-type-mat-native-select.mat-form-field-invalid
  .mat-form-field-infix:after {
  color: #C00000;
}
.mat-list-base .mat-list-item,
.mat-list-base .mat-list-option {
  color: #000000de;
}
.mat-list-base .mat-subheader {
  color: #0000008a;
}
.mat-list-base .mat-list-item-disabled {
  background-color: #eee;
  color: #00000061;
}
.mat-list-option:hover,
.mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.mat-list-single-selected-option,
.mat-list-single-selected-option:hover,
.mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.mat-menu-panel {
  background: white;
}
.mat-menu-panel:not([class*="mat-elevation-z"]) {
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
}
.mat-menu-item {
  background: transparent;
  color: #000000de;
}
.mat-menu-item[disabled],
.mat-menu-item[disabled] .mat-menu-submenu-icon,
.mat-menu-item[disabled] .mat-icon-no-color {
  color: #00000061;
}
.mat-menu-item .mat-icon-no-color,
.mat-menu-submenu-icon {
  color: #0000008a;
}
.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-paginator {
  background: white;
}
.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: #0000008a;
}
.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: #00000061;
}
.mat-paginator-container {
  min-height: 56px;
}
.mat-progress-bar-background {
  fill: #e2bcbc;
}
.mat-progress-bar-buffer {
  background-color: #e2bcbc;
}
.mat-progress-bar-fill:after {
  background-color: #900;
}
.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #d3d1d4;
}
.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #d3d1d4;
}
.mat-progress-bar.mat-accent .mat-progress-bar-fill:after {
  background-color: #5d5760;
}
.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #e0bcbc;
}
.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #e0bcbc;
}
.mat-progress-bar.mat-warn .mat-progress-bar-fill:after {
  background-color: #C00000;
}
.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: #900;
}
.mat-progress-spinner.mat-accent circle,
.mat-spinner.mat-accent circle {
  stroke: #5d5760;
}
.mat-progress-spinner.mat-warn circle,
.mat-spinner.mat-warn circle {
  stroke: #C00000;
}
.mat-radio-outer-circle {
  border-color: #0000008a;
}
.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #900;
}
.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary
  .mat-radio-ripple
  .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #900;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #5d5760;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent
  .mat-radio-ripple
  .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #5d5760;
}
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #C00000;
}
.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn
  .mat-radio-ripple
  .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #C00000;
}
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: #00000061;
}
.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: #00000061;
}
.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: #00000061;
}
.mat-radio-button .mat-ripple-element {
  background-color: #000;
}
.mat-select-value {
  color: #000000de;
}
.mat-select-placeholder {
  color: #0000006b;
}
.mat-select-disabled .mat-select-value {
  color: #00000061;
}
.mat-select-arrow {
  color: #0000008a;
}
.mat-select-panel {
  background: white;
}
.mat-select-panel:not([class*="mat-elevation-z"]) {
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #900;
}
.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #5d5760;
}
.mat-form-field.mat-focused.mat-warn .mat-select-arrow,
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #C00000;
}
.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: #00000061;
}
.mat-drawer-container {
  background-color: #fafafa;
  color: #000000de;
}
.mat-drawer {
  background-color: #fff;
  color: #000000de;
}
.mat-drawer.mat-drawer-push {
  background-color: #fff;
}
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0 8px 10px -5px #0003, 0 16px 24px 2px #00000024,
    0 6px 30px 5px #0000001f;
}
.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-drawer-side.mat-drawer-end,
[dir="rtl"] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
[dir="rtl"] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-drawer-backdrop.mat-drawer-shown {
  background-color: #0009;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #5d5760;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #5d57608a;
}
.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #5d5760;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #900;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: #9900008a;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #900;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #C00000;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: #C000008a;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #C00000;
}
.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: #000;
}
.mat-slide-toggle-thumb {
  box-shadow: 0 2px 1px -1px #0003, 0 1px 1px #00000024, 0 1px 3px #0000001f;
  background-color: #fafafa;
}
.mat-slide-toggle-bar {
  background-color: #00000061;
}
.mat-slider-track-background {
  background-color: #00000042;
}
.mat-slider.mat-primary .mat-slider-track-fill,
.mat-slider.mat-primary .mat-slider-thumb,
.mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #900;
}
.mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: #900;
}
.mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: #9003;
}
.mat-slider.mat-accent .mat-slider-track-fill,
.mat-slider.mat-accent .mat-slider-thumb,
.mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #5d5760;
}
.mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: #d8d4d9;
}
.mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: #5d576033;
}
.mat-slider.mat-warn .mat-slider-track-fill,
.mat-slider.mat-warn .mat-slider-thumb,
.mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #C00000;
}
.mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: #900;
}
.mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: #C0000033;
}
.mat-slider:hover .mat-slider-track-background,
.mat-slider.cdk-focused .mat-slider-track-background {
  background-color: #00000061;
}
.mat-slider.mat-slider-disabled .mat-slider-track-background,
.mat-slider.mat-slider-disabled .mat-slider-track-fill,
.mat-slider.mat-slider-disabled .mat-slider-thumb,
.mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: #00000042;
}
.mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: #0000001f;
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing
  .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing
  .mat-slider-thumb-label {
  background-color: #000000de;
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused
  .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused
  .mat-slider-thumb-label {
  background-color: #00000042;
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing)
  .mat-slider-thumb {
  border-color: #00000042;
  background-color: transparent;
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover
  .mat-slider-thumb,
.mat-slider.mat-slider-min-value:not(
    .mat-slider-thumb-label-showing
  ).cdk-focused
  .mat-slider-thumb {
  border-color: #00000061;
}
.mat-slider.mat-slider-min-value:not(
    .mat-slider-thumb-label-showing
  ):hover.mat-slider-disabled
  .mat-slider-thumb,
.mat-slider.mat-slider-min-value:not(
    .mat-slider-thumb-label-showing
  ).cdk-focused.mat-slider-disabled
  .mat-slider-thumb {
  border-color: #00000042;
}
.mat-slider-has-ticks .mat-slider-wrapper:after {
  border-color: #000000b3;
}
.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(
    to right,
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.7) 2px,
    transparent 0,
    transparent
  );
  background-image: -moz-repeating-linear-gradient(
    0.0001deg,
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.7) 2px,
    transparent 0,
    transparent
  );
}
.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.7) 2px,
    transparent 0,
    transparent
  );
}
.mat-step-header.cdk-keyboard-focused,
.mat-step-header.cdk-program-focused,
.mat-step-header:hover:not([aria-disabled]),
.mat-step-header:hover[aria-disabled="false"] {
  background-color: #0000000a;
}
.mat-step-header:hover[aria-disabled="true"] {
  cursor: default;
}
@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: #0000008a;
}
.mat-step-header .mat-step-icon {
  background-color: #0000008a;
  color: #900;
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #900;
  color: #900;
}
.mat-step-header.mat-accent .mat-step-icon {
  color: #d8d4d9;
}
.mat-step-header.mat-accent .mat-step-icon-selected,
.mat-step-header.mat-accent .mat-step-icon-state-done,
.mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #5d5760;
  color: #d8d4d9;
}
.mat-step-header.mat-warn .mat-step-icon {
  color: #900;
}
.mat-step-header.mat-warn .mat-step-icon-selected,
.mat-step-header.mat-warn .mat-step-icon-state-done,
.mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #C00000;
  color: #900;
}
.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #C00000;
}
.mat-step-header .mat-step-label.mat-step-label-active {
  color: #000000de;
}
.mat-step-header .mat-step-label.mat-step-label-error {
  color: #C00000;
}
.mat-stepper-horizontal,
.mat-stepper-vertical {
  background-color: #fff;
}
.mat-stepper-vertical-line:before {
  border-left-color: #0000001f;
}
.mat-horizontal-stepper-header:before,
.mat-horizontal-stepper-header:after,
.mat-stepper-horizontal-line {
  border-top-color: #0000001f;
}
.mat-horizontal-stepper-header {
  height: 72px;
}
.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px;
}
.mat-stepper-vertical-line:before {
  top: -16px;
  bottom: -16px;
}
.mat-stepper-label-position-bottom .mat-horizontal-stepper-header:after,
.mat-stepper-label-position-bottom .mat-horizontal-stepper-header:before {
  top: 36px;
}
.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}
.mat-sort-header-arrow {
  color: #757575;
}
.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.mat-tab-label,
.mat-tab-link {
  color: #000000de;
}
.mat-tab-label.mat-tab-disabled,
.mat-tab-link.mat-tab-disabled {
  color: #00000061;
}
.mat-tab-header-pagination-chevron {
  border-color: #000000de;
}
.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: #00000061;
}
.mat-tab-group[class*="mat-background-"] > .mat-tab-header,
.mat-tab-nav-bar[class*="mat-background-"] {
  border-bottom: none;
  border-top: none;
}
.mat-tab-group.mat-primary
  .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary
  .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary
  .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary
  .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary
  .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary
  .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary
  .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary
  .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: #e0b3b34d;
}
.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #900;
}
.mat-tab-group.mat-primary.mat-background-primary
  > .mat-tab-header
  .mat-ink-bar,
.mat-tab-group.mat-primary.mat-background-primary
  > .mat-tab-link-container
  .mat-ink-bar,
.mat-tab-nav-bar.mat-primary.mat-background-primary
  > .mat-tab-header
  .mat-ink-bar,
.mat-tab-nav-bar.mat-primary.mat-background-primary
  > .mat-tab-link-container
  .mat-ink-bar {
  background-color: #900;
}
.mat-tab-group.mat-accent
  .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent
  .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent
  .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent
  .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent
  .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent
  .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent
  .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent
  .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: #cecdcf4d;
}
.mat-tab-group.mat-accent .mat-ink-bar,
.mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #5d5760;
}
.mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar,
.mat-tab-group.mat-accent.mat-background-accent
  > .mat-tab-link-container
  .mat-ink-bar,
.mat-tab-nav-bar.mat-accent.mat-background-accent
  > .mat-tab-header
  .mat-ink-bar,
.mat-tab-nav-bar.mat-accent.mat-background-accent
  > .mat-tab-link-container
  .mat-ink-bar {
  background-color: #d8d4d9;
}
.mat-tab-group.mat-warn
  .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn
  .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn
  .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn
  .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn
  .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn
  .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn
  .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn
  .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: #deb3b34d;
}
.mat-tab-group.mat-warn .mat-ink-bar,
.mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #C00000;
}
.mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar,
.mat-tab-group.mat-warn.mat-background-warn
  > .mat-tab-link-container
  .mat-ink-bar,
.mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar,
.mat-tab-nav-bar.mat-warn.mat-background-warn
  > .mat-tab-link-container
  .mat-ink-bar {
  background-color: #900;
}
.mat-tab-group.mat-background-primary
  .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary
  .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary
  .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary
  .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary
  .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary
  .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary
  .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary
  .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: #e0b3b34d;
}
.mat-tab-group.mat-background-primary > .mat-tab-header,
.mat-tab-group.mat-background-primary > .mat-tab-link-container,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #900;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label,
.mat-tab-nav-bar.mat-background-primary
  > .mat-tab-link-container
  .mat-tab-link {
  color: #900;
}
.mat-tab-group.mat-background-primary
  > .mat-tab-header
  .mat-tab-label.mat-tab-disabled,
.mat-tab-group.mat-background-primary
  > .mat-tab-link-container
  .mat-tab-link.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-primary
  > .mat-tab-header
  .mat-tab-label.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-primary
  > .mat-tab-link-container
  .mat-tab-link.mat-tab-disabled {
  color: #9006;
}
.mat-tab-group.mat-background-primary
  > .mat-tab-header
  .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary
  > .mat-tab-header-pagination
  .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary
  > .mat-tab-link-container
  .mat-focus-indicator:before,
.mat-tab-group.mat-background-primary
  > .mat-tab-header
  .mat-focus-indicator:before,
.mat-tab-nav-bar.mat-background-primary
  > .mat-tab-header
  .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary
  > .mat-tab-header-pagination
  .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary
  > .mat-tab-link-container
  .mat-focus-indicator:before,
.mat-tab-nav-bar.mat-background-primary
  > .mat-tab-header
  .mat-focus-indicator:before {
  border-color: #900;
}
.mat-tab-group.mat-background-primary
  > .mat-tab-header
  .mat-tab-header-pagination-disabled
  .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary
  > .mat-tab-header-pagination-disabled
  .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary
  > .mat-tab-header
  .mat-tab-header-pagination-disabled
  .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary
  > .mat-tab-header-pagination-disabled
  .mat-tab-header-pagination-chevron {
  border-color: #900;
  opacity: 0.4;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-primary
  > .mat-tab-link-container
  .mat-ripple-element,
.mat-tab-group.mat-background-primary
  > .mat-tab-header-pagination
  .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary
  > .mat-tab-link-container
  .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary
  > .mat-tab-header-pagination
  .mat-ripple-element {
  background-color: #900;
  opacity: 0.12;
}
.mat-tab-group.mat-background-accent
  .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent
  .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent
  .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent
  .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent
  .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent
  .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent
  .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent
  .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: #cecdcf4d;
}
.mat-tab-group.mat-background-accent > .mat-tab-header,
.mat-tab-group.mat-background-accent > .mat-tab-link-container,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #5d5760;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: #d8d4d9;
}
.mat-tab-group.mat-background-accent
  > .mat-tab-header
  .mat-tab-label.mat-tab-disabled,
.mat-tab-group.mat-background-accent
  > .mat-tab-link-container
  .mat-tab-link.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-accent
  > .mat-tab-header
  .mat-tab-label.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-accent
  > .mat-tab-link-container
  .mat-tab-link.mat-tab-disabled {
  color: #d8d4d966;
}
.mat-tab-group.mat-background-accent
  > .mat-tab-header
  .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent
  > .mat-tab-header-pagination
  .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent
  > .mat-tab-link-container
  .mat-focus-indicator:before,
.mat-tab-group.mat-background-accent
  > .mat-tab-header
  .mat-focus-indicator:before,
.mat-tab-nav-bar.mat-background-accent
  > .mat-tab-header
  .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent
  > .mat-tab-header-pagination
  .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent
  > .mat-tab-link-container
  .mat-focus-indicator:before,
.mat-tab-nav-bar.mat-background-accent
  > .mat-tab-header
  .mat-focus-indicator:before {
  border-color: #d8d4d9;
}
.mat-tab-group.mat-background-accent
  > .mat-tab-header
  .mat-tab-header-pagination-disabled
  .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent
  > .mat-tab-header-pagination-disabled
  .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent
  > .mat-tab-header
  .mat-tab-header-pagination-disabled
  .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent
  > .mat-tab-header-pagination-disabled
  .mat-tab-header-pagination-chevron {
  border-color: #d8d4d9;
  opacity: 0.4;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-accent
  > .mat-tab-link-container
  .mat-ripple-element,
.mat-tab-group.mat-background-accent
  > .mat-tab-header-pagination
  .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent
  > .mat-tab-link-container
  .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent
  > .mat-tab-header-pagination
  .mat-ripple-element {
  background-color: #d8d4d9;
  opacity: 0.12;
}
.mat-tab-group.mat-background-warn
  .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn
  .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn
  .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn
  .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn
  .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn
  .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn
  .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn
  .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: #deb3b34d;
}
.mat-tab-group.mat-background-warn > .mat-tab-header,
.mat-tab-group.mat-background-warn > .mat-tab-link-container,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #C00000;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: #900;
}
.mat-tab-group.mat-background-warn
  > .mat-tab-header
  .mat-tab-label.mat-tab-disabled,
.mat-tab-group.mat-background-warn
  > .mat-tab-link-container
  .mat-tab-link.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-warn
  > .mat-tab-header
  .mat-tab-label.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-warn
  > .mat-tab-link-container
  .mat-tab-link.mat-tab-disabled {
  color: #9006;
}
.mat-tab-group.mat-background-warn
  > .mat-tab-header
  .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn
  > .mat-tab-header-pagination
  .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn
  > .mat-tab-link-container
  .mat-focus-indicator:before,
.mat-tab-group.mat-background-warn
  > .mat-tab-header
  .mat-focus-indicator:before,
.mat-tab-nav-bar.mat-background-warn
  > .mat-tab-header
  .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn
  > .mat-tab-header-pagination
  .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn
  > .mat-tab-link-container
  .mat-focus-indicator:before,
.mat-tab-nav-bar.mat-background-warn
  > .mat-tab-header
  .mat-focus-indicator:before {
  border-color: #900;
}
.mat-tab-group.mat-background-warn
  > .mat-tab-header
  .mat-tab-header-pagination-disabled
  .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn
  > .mat-tab-header-pagination-disabled
  .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn
  > .mat-tab-header
  .mat-tab-header-pagination-disabled
  .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn
  > .mat-tab-header-pagination-disabled
  .mat-tab-header-pagination-chevron {
  border-color: #900;
  opacity: 0.4;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-warn
  > .mat-tab-link-container
  .mat-ripple-element,
.mat-tab-group.mat-background-warn
  > .mat-tab-header-pagination
  .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn
  > .mat-tab-link-container
  .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn
  > .mat-tab-header-pagination
  .mat-ripple-element {
  background-color: #900;
  opacity: 0.12;
}
.mat-toolbar {
  background: whitesmoke;
  color: #000000de;
}
.mat-toolbar.mat-primary {
  background: #990000;
  color: #900;
}
.mat-toolbar.mat-accent {
  background: #5d5760;
  color: #d8d4d9;
}
.mat-toolbar.mat-warn {
  background: #C00000;
  color: #900;
}
.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.mat-toolbar-multiple-rows {
  min-height: 64px;
}
.mat-toolbar-row,
.mat-toolbar-single-row {
  height: 64px;
}
@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }
  .mat-toolbar-row,
  .mat-toolbar-single-row {
    height: 56px;
  }
}
.mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.mat-tree {
  background: white;
}
.mat-tree-node,
.mat-nested-tree-node {
  color: #000000de;
}
.mat-tree-node {
  min-height: 48px;
}
.mat-snack-bar-container {
  color: #ffffffb3;
  background: #323232;
  box-shadow: 0 3px 5px -1px #0003, 0 6px 10px #00000024, 0 1px 18px #0000001f;
}
.mat-simple-snackbar-action {
  color: #5d5760;
}
.ctti-mobile-hero {
  font-size: 34px;
  line-height: 42px;
  font-weight: 700;
  font-family: Open Sans, sans-serif;
}
.ctti-mobile-h1 {
  font-size: 1.875rem;
  line-height: 38px;
  font-weight: 600;
  font-family: Open Sans, sans-serif;
}
.ctti-mobile-h2 {
  font-size: 1.625rem;
  line-height: 34px;
  font-weight: 600;
  font-family: Open Sans, sans-serif;
}
.ctti-mobile-h3 {
  font-size: 1.5rem;
  line-height: 30px;
  font-weight: 600;
  font-family: Open Sans, sans-serif;
}
.ctti-mobile-h4 {
  font-size: 1.375rem;
  line-height: 28px;
  font-weight: 600;
  font-family: Open Sans, sans-serif;
}
.ctti-mobile-h5 {
  font-size: 1.25rem;
  line-height: 26px;
  font-weight: 600;
  font-family: Open Sans, sans-serif;
}
.ctti-mobile-h6 {
  font-size: 1.125rem;
  line-height: 24px;
  font-weight: 600;
  font-family: Open Sans, sans-serif;
}
.ctti-mobile-body {
  font-size: 1rem;
  line-height: 24px;
  font-family: Open Sans, sans-serif;
  color: #5d5760;
}
.ctti-mobile-body-bold {
  font-size: 1rem;
  line-height: 24px;
  font-family: Open Sans, sans-serif;
  color: #5d5760;
  font-weight: 700;
}
.ctti-mobile-link {
  font-size: 1rem;
  line-height: 24px;
  color: #5d5760;
  color: #C00000;
  text-decoration: underline;
  text-underline-offset: 5px;
  font-family: Open Sans, sans-serif;
  cursor: pointer;
}
@media (min-width: 768px) {
  .ctti-mobile-link {
    font-size: 1.125rem;
    line-height: 26px;
  }
}
.ctti-mobile-link:hover {
  color: #C00000;
  text-decoration: underline;
  text-decoration-thickness: 3px;
}
.ctti-mobile-link-small {
  font-size: 0.75rem;
  line-height: 16px;
  color: #C00000;
  text-decoration: underline;
  text-underline-offset: 5px;
  cursor: pointer;
}
.ctti-mobile-link-small:hover {
  color: #C00000;
  text-decoration: underline;
  text-decoration-thickness: 3px;
  cursor: pointer;
}
.ctti-mobile-label {
  font-size: 0.875rem;
  line-height: 20px;
  color: #5d5760;
}
.ctti-mobile-label-bold {
  font-size: 0.875rem;
  line-height: 20px;
  font-family: Open Sans, sans-serif;
  color: #5d5760;
  font-weight: 700;
}
.ctti-mobile-link-label {
  font-size: 0.875rem;
  line-height: 20px;
  color: #C00000;
  text-decoration: underline;
  text-underline-offset: 5px;
  cursor: pointer;
}
.ctti-mobile-link-label:hover {
  color: #C00000;
  text-decoration: underline;
  text-decoration-thickness: 3px;
}
.ctti-mobile-small {
  font-size: 0.75rem;
  line-height: 16px;
  font-family: Open Sans, sans-serif;
  color: #5d5760;
  letter-spacing: 0.4px;
}
.ctti-mobile-small-bold {
  font-size: 0.75rem;
  line-height: 16px;
  font-family: Open Sans, sans-serif;
  color: #5d5760;
  letter-spacing: 0.4px;
  font-weight: 700;
}
.ctti-invalid-message {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #ea0003;
  margin-top: 8px;
  display: block;
}
.ctti-required-message {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #5d5760;
  margin-top: 8px;
  display: block;
}
.ctti-invalid-icon {
  display: block;
}
.ctti-invalid-icon:before {
  font-family: Material Icons;
  content: "warning_amber";
  position: absolute;
  font-size: 20px;
  color: #ea0003;
  right: 0;
  top: 10px;
  padding-right: 16px;
  -webkit-font-feature-settings: "liga";
}
.ctti-flex {
  display: flex;
}
.ctti-center {
  justify-content: center;
}
h1 {
  font-size: 1.875rem;
  line-height: 38px;
  font-weight: 600;
  color: #121112;
  font-family: Open Sans, sans-serif;
}
@media (min-width: 768px) {
  h1 {
    font-size: 2.25rem;
    line-height: 44px;
  }
}
h2 {
  font-size: 1.625rem;
  line-height: 34px;
  font-weight: 600;
  color: #121112;
  font-family: Open Sans, sans-serif;
}
@media (min-width: 768px) {
  h2 {
    font-size: 2rem;
    line-height: 40px;
  }
}
h3 {
  font-size: 1.5rem;
  line-height: 30px;
  color: #121112;
  font-family: Open Sans, sans-serif;
  font-weight: 600;
}
@media (min-width: 768px) {
  h3 {
    font-size: 1.75rem;
    line-height: 36px;
  }
}
h4 {
  font-size: 1.375rem;
  line-height: 28px;
  font-weight: 600;
  color: #121112;
  font-family: Open Sans, sans-serif;
}
@media (min-width: 768px) {
  h4 {
    font-size: 1.5rem;
    line-height: 32px;
  }
}
h5 {
  font-size: 1.25rem;
  line-height: 26px;
  font-weight: 600;
  color: #121112;
  font-family: Open Sans, sans-serif;
}
@media (min-width: 768px) {
  h5 {
    font-size: 1.375rem;
    line-height: 28px;
  }
}
h6 {
  font-size: 1.125rem;
  line-height: 24px;
  font-weight: 600;
  color: #121112;
  font-family: Open Sans, sans-serif;
}
@media (min-width: 768px) {
  h6 {
    font-size: 1.25rem;
    line-height: 26px;
  }
}
@media (min-width: 768px) {
p a {
  font-size: 1rem;
  line-height: 2;
  font-family: Open Sans, sans-serif;
  color: #C00000;
  text-decoration: underline;
  text-underline-offset: 5px;
}
@media (min-width: 768px) {
  p a {
    font-size: 1.125rem;
    line-height: 1.5;
  }
}
p a:hover {
  color: #C00000;
  text-decoration: underline;
  text-decoration-thickness: 3px;
}
strong,
b {
  font-weight: 600;
}
label {
  font-size: 0.875rem;
  line-height: 20px;
  font-family: Open Sans, sans-serif;
  color: #5d5760;
}
@media (min-width: 768px) {
  label {
    font-size: 1rem;
    line-height: 26px;
  }
}
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: PT Sans, Helvetica Neue, sans-serif;
}
ul {
  padding-left: 0;
}
ul li {
  list-style-type: none;
}
table {
  width: 100%;
}
a {
  text-decoration: none;
}
.ctti-card {
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  padding: 24px;
  background-color: #fff;
  box-sizing: border-box;
  position: relative;
  height: 216px;
  overflow: hidden;
}
.ctti-card:hover {
  box-shadow: 0 4px 8px #1e000052;
}
.ctti-card:hover:before {
  content: "";
  border: 1px solid #C00000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
}
.ctti-card__image {
  height: 128px;
  width: 100%;
  object-fit: cover;
  overflow: hidden;
}
.ctti-accordion__item {
  display: block;
  border-bottom: 1px solid #121112;
}
.ctti-accordion__header {
  height: 48px;
  width: 100%;
  background-color: #fff;
}
.ctti-accordion__button {
  -webkit-appearance: none;
  appearance: none;
  padding: 12px 24px;
  height: 48px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  box-sizing: border-box;
  border: 0;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
}
.ctti-accordion__button:before {
  content: "";
  font-family: Material Icons;
  content: "add";
  position: absolute;
  right: 0;
  padding-right: 16px;
  -webkit-font-feature-settings: "liga";
}
.ctti-accordion__button:focus:not(:active) {
  z-index: 3;
  outline: 0;
  border: 1px solid #0080fa;
}
.ctti-accordion__button:hover {
  background-color: #f9f7f9;
}
.ctti-accordion__button:active {
  outline: 0;
  background-color: #fff;
  border-bottom: 2px solid #121112;
}
.ctti-accordion__button[aria-expanded="true"] {
  background-color: #f9f7f9;
}
.ctti-accordion__button[aria-expanded="true"]:before {
  content: "remove";
}
.ctti-accordion__body {
  padding: 16px;
  background-color: #f9f7f9;
  color: #000;
}
.ctti-panel-accordion .mat-expansion-panel:not([class*="mat-elevation-z"]) {
  box-shadow: none;
}
.ctti-panel-accordion .mat-accordion .mat-expansion-panel:first-of-type {
  border-radius: 0;
}
.ctti-panel-accordion .mat-expansion-panel {
  border-radius: 0 !important;
}
.ctti-panel-accordion .mat-expansion-panel-header {
  border-bottom: 1px solid #121112;
  height: 3rem;
}
.ctti-panel-accordion .mat-expansion-panel-header-title {
  color: #121112;
  font-size: 1.125rem;
  line-height: 1.625rem;
  font-family: Open Sans, sans-serif;
  font-weight: 600;
}
.ctti-panel-accordion .mat-expansion-panel-header-description {
  position: absolute;
  right: 0;
  color: #121112;
}
.ctti-panel-accordion .mat-expansion-panel-body {
  font-size: 1.125rem;
  line-height: 26px;
  font-family: Open Sans, sans-serif;
  color: #5d5760;
  background-color: #f9f7f9;
  border-bottom: 2.5px solid #121112;
}
.ctti-panel-accordion .mat-expansion-panel-body p {
  padding-top: 1rem;
}
.ctti-panel-accordion .mat-expanded {
  background-color: #f9f7f9;
  border-bottom: none;
}
.ctti-panel-accordion .mat-expansion-panel-spacing {
  margin: 0;
}
.ctti-panel-accordion .mat-expansion-panel-header:focus:not(:active) {
  z-index: 3;
  outline: 0;
  border: 1px solid #0080fa;
}
.ctti-panel-accordion .mat-expansion-panel-header[aria-disabled="true"] {
  background-color: #f9f7f9;
  border-bottom: 1px solid #d8d4d9;
  cursor: no-drop;
}
.ctti-panel-accordion
  .ctti-panel-accordion
  .mat-expansion-panel:not([class*="mat-elevation-z"]) {
  box-shadow: none;
  border-bottom: 1px solid #121112;
}
.title-ng-acc {
  font-size: 1.75rem;
  line-height: 36px;
  padding: 3rem 0rem 1rem 5.3rem;
  color: #121112;
  font-family: Open Sans, sans-serif;
  font-weight: 600;
}
button:disabled {
  pointer-events: none;
  cursor: no-drop;
}
.ctti-button,
.ctti-paginator button {
  font-size: 1rem;
  line-height: 24px;
  font-weight: 600;
  font-family: Open Sans, sans-serif;
  -webkit-appearance: none;
  appearance: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 12px;
  min-height: 48px;
  border-radius: 4px;
  box-sizing: border-box;
  border: 0;
  position: relative;
  height: -moz-fit-content;
  height: fit-content;
  cursor: pointer;
}
.ctti-button:focus:not(:focus-visible):active,
.ctti-paginator button:focus:not(:focus-visible):active {
  outline-color: transparent;
}
.ctti-button--md {
  height: 40px;
  font-size: 18px;
  line-height: 24px;
  padding: 8px 16px;
}
.ctti-button--sm,
.ctti-paginator button {
  min-height: 32px;
  font-size: 16px;
  line-height: 24px;
  padding: 4px 12px;
}

.ctti-button-filled:hover {
  background: #c00000;
  color: #fff;
  border: 1px solid #121112;
  box-shadow: 0 4px 8px 2px #1e000066;
}
.ctti-button-filled:active {
  background: #c00000;
  border: 1px solid #121112;
  color: #fff;
  box-shadow: none;
}
.ctti-button-filled:focus:not(:active) {
  background: #C00000;
  
  outline-offset: 0.125rem;
  color: #fff;
}
.ctti-button-filled:disabled {
  background: #d8d4d9;
  color: #959198;
  border: 1px solid #d8d4d9;
}
.ctti-button-outlined,
.ctti-paginator button {
  border: 1px solid #C00000;
  background: white;
  color: #C00000;
}
.ctti-button-outlined:hover,
.ctti-paginator button:hover {
  border: 1px solid #C00000;
  box-shadow: 0 4px 8px #1e000052;
}
.ctti-button-outlined:active,
.ctti-paginator button:active {
  background: #fbf1f1;
  color: #C00000;
  border: 1px solid #c00000;
  box-shadow: none;
}
.ctti-button-outlined:focus:not(:active),
.ctti-paginator button:focus:not(:active) {
  background: #fbf1f1;
  
  outline-offset: 0.125rem;
  border: 1px solid #fbf1f1;
  color: #C00000;
}
.ctti-button-outlined:disabled,
.ctti-paginator button:disabled {
  border-color: #d8d4d9;
  background: white;
  color: #d8d4d9;
}
.ctti-button-text {
  font-size: 1rem;
  line-height: 24px;
  font-weight: 600;
  font-family: Open Sans, sans-serif;
  background: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  color: #C00000;
}
@media (min-width: 768px) {
  .ctti-button-text {
    font-size: 1.125rem;
    line-height: 26px;
  }
}
.ctti-button-text:hover {
  background: white;
  box-shadow: 0 2px 2px #1e000052;
}
.ctti-button-text:active {
  background: white;
  color: #C00000;
  border-color: #c00000;
  box-shadow: none;
}
.ctti-button-text:focus:not(:active) {
  background: white;
  
  outline-offset: 0.125rem;
  color: #C00000;
}
.ctti-button-text:disabled {
  border-color: #fff;
  background: white;
  color: #d8d4d9;
}
.ctti-button-tonalOld {
  border: 0;
  background: #fbf1f1;
  color: #C00000;
}
.ctti-button-tonalOld:hover {
  background: #efbfbf;
  box-shadow: 0 4px 8px #1e000052;
}
.ctti-button-tonalOld:active {
  background: #efbfbf;
  color: #600000;
  box-shadow: none;
}
.ctti-button-tonalOld:focus:not(:active) {
  background: #fbf1f1;
  
  outline-offset: 0.125rem;
  color: #C00000;
}
.ctti-button-tonalOld:disabled {
  background: #f9f7f9;
  color: #d8d4d9;
}
.ctti-button-tonal {
  border: 0;
  background: #fbf1f1;
  color: #C00000;
}
.ctti-button-tonal:hover {
  background: #efbfbf;
  box-shadow: 0 4px 8px #1e000052;
}
.ctti-button-tonal:active {
  background: #efbfbf;
  color: #600000;
  box-shadow: none;
}
.ctti-button-tonal:focus:not(:active) {
  background: #fbf1f1;
  
  outline-offset: 0.125rem;
  color: #C00000;
}
.ctti-button-tonal:disabled {
  background: #f9f7f9;
  color: #d8d4d9;
}
.ctti-button-action {
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  background: white;
  box-sizing: border-box;
  color: #121112;
  border: 1px solid #121112;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}
.ctti-button-action:hover {
  box-shadow: 0 4px 8px #1e000052;
}
.ctti-button-action:active {
  border: 2px solid #121112;
}
.ctti-button-action:focus:not(:active) {
  border: 1px solid #0080fa;
  outline: 0;
}
.ctti-button-action:disabled {
  color: #d8d4d9;
  border: 1px solid #d8d4d9;
}
.ctti-button-icon,
.ctti-paginator button {
  min-height: 48px;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  padding: 12px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}
.ctti-button-icon--md {
  min-height: 40px;
  padding: 0.623rem;
  height: -moz-fit-content;
  height: fit-content;
}
.ctti-button-icon--sm {
  min-height: 32px;
  padding: 0.625rem;
  height: -moz-fit-content;
  height: fit-content;
}
.ctti-button-icon-text {
  font-size: 1rem;
  line-height: 24px;
  font-weight: 600;
  font-family: Open Sans, sans-serif;
  padding: 12px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 48px;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}
@media (min-width: 768px) {
  .ctti-button-icon-text {
    font-size: 1.125rem;
    line-height: 26px;
  }
}
.ctti-button-icon-text--md {
  min-height: 40px;
  font-size: 18px;
  line-height: 24px;
  padding: 8px 16px;
}
.ctti-button-icon-text--sm {
  min-height: 32px;
  font-size: 16px;
  line-height: 24px;
  padding: 4px 12px;
}
.ctti-datepicker {
  display: flex;
  min-width: 298px;
  height: auto;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  position: relative;
}
.ctti-datepicker input {
  width: 100%;
  height: 48px;
  display: flex;
  background-color: #fff;
  border-style: solid;
  border-width: 1px;
  border-color: #121112;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 1rem;
  line-height: 24px;
  font-weight: 600;
  font-family: Open Sans, sans-serif;
  box-sizing: border-box;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  position: relative;
}
@media (min-width: 768px) {
  .ctti-datepicker input {
    font-size: 1.125rem;
    line-height: 26px;
  }
}
.ctti-datepicker input::placeholder {
  font-weight: 400;
  color: #5d5760;
  opacity: 1;
}
.ctti-datepicker input::-moz-placeholder {
  font-weight: 400;
  color: #5d5760;
  opacity: 1;
}
.ctti-datepicker input::-webkit-input-placeholder {
  font-weight: 400;
  color: #5d5760;
  opacity: 1;
}
.ctti-datepicker input:disabled {
  background-color: #f9f7f9;
  border-color: #d8d4d9;
  color: #d8d4d9;
  pointer-events: none;
}
.ctti-datepicker input:disabled::placeholder {
  font-weight: 400;
  color: #d8d4d9;
}
.ctti-datepicker input:disabled::-moz-placeholder {
  font-weight: 400;
  color: #d8d4d9;
}
.ctti-datepicker input:disabled::-webkit-input-placeholder {
  font-weight: 400;
  color: #d8d4d9;
}
.ctti-datepicker input:read-only,
.ctti-datepicker input:read-only:hover,
.ctti-datepicker input:read-only:focus {
  background-color: #f9f7f9;
  border-color: #d8d4d9;
  color: #121112;
  pointer-events: none;
  box-shadow: none;
}
.ctti-datepicker input:hover {
  box-shadow: 0 4px 8px #1e000052;
  border-color: #C00000;
}
.ctti-datepicker input:focus {
  box-shadow: 0 0 0 1px #121112;
  border-color: #121112;
  color: #121112;
}
.ctti-datepicker input:read-write:not(:placeholder-shown) {
  box-shadow: 0 0 0 1px #121112;
  border-color: #121112;
  color: #121112;
}
.ctti-datepicker input:focus-visible {
  outline: none;
}
.ctti-datepicker input:required {
  border-color: #121112;
}
.ctti-datepicker input:invalid {
  box-shadow: 0 0 0 1px #ea0003 !important;
  border-color: #ea0003 !important;
}
.ctti-datepicker button {
  height: 100%;
  width: 40px;
  background-color: transparent;
  border: 0;
  right: 0;
  box-sizing: border-box;
  position: absolute;
  display: flex;
  align-items: center;
}
.ctti-datepicker--disabled {
  background-color: #f9f7f9;
  border-color: #d8d4d9;
  color: #d8d4d9;
  pointer-events: none;
}
.ctti-datepicker--disabled input {
  background-color: #f9f7f9;
}
.ctti-datepicker--disabled input::placeholder {
  color: #d8d4d9;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
}
.ctti-datepicker--disabled button {
  color: #d8d4d9;
}
.ctti-datepicker--readonly {
  background-color: #f9f7f9;
  border-color: #d8d4d9;
  color: #d8d4d9;
  pointer-events: none;
}
.ctti-datepicker--readonly button {
  color: #d8d4d9;
}
.ctti-datepicker .ctti-invalid-icon:before {
  right: 30px;
}
.mat-datepicker-content {
  margin-left: -1px;
  margin-top: 4px;
  border: 1px solid #C00000;
  box-shadow: 0 4px 8px #1e000052;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}
.mat-calendar-body-today:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ) {
  font-size: 1rem;
  line-height: 24px;
  font-family: Open Sans, sans-serif;
  color: #5d5760;
  font-weight: 600;
  color: #121112;
  border-color: #121112;
}
@media (min-width: 768px) {
  .mat-calendar-body-today:not(.mat-calendar-body-selected):not(
      .mat-calendar-body-comparison-identical
    ) {
    font-size: 1.125rem;
    line-height: 26px;
  }
}
.mat-calendar-body-cell-content {
  height: 80% !important;
  width: 80% !important;
  border-radius: 4px !important;
  font-weight: 400;
  font-size: 1.125rem;
}
.mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  color: #121112;
}
.mat-calendar-body-selected {
  background-color: #C00000;
  font-size: 1rem;
  line-height: 24px;
  font-family: Open Sans, sans-serif;
  color: #5d5760;
  font-weight: 600;
  color: #fff;
}
@media (min-width: 768px) {
  .mat-calendar-body-selected {
    font-size: 1.125rem;
    line-height: 26px;
  }
}
.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ) {
  background-color: #fbf1f1;
}
.mat-error {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #ea0003;
}
.mat-date-range-input-hide-placeholders {
  border: 2px solid;
}
.ctti-daterange {
  display: flex;
  min-width: 298px;
  height: auto;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  position: relative;
  border: 1px solid black;
  height: 50px;
}
.ctti-daterange:hover {
  box-shadow: 0 4px 8px #1e000052;
  border-color: #C00000;
}
.ctti-daterange button {
  height: 100%;
  width: 40px;
  background-color: transparent;
  border: 0;
  right: 0;
  box-sizing: border-box;
  position: absolute;
  display: flex;
  align-items: center;
}
.ctti-daterange .mat-date-range-input {
  display: flex !important;
  align-items: center;
}
.specialHover:hover {
  border-color: initial !important;
}
.ctti-daterange-input {
  padding-left: 10px;
}
.mat-date-range-input-start-wrapper .mat-date-range-input-inner {
  border-color: #d8d4d9;
  color: #121112;
  pointer-events: none;
  box-shadow: none;
  font-weight: 600;
  font-family: Open Sans;
  line-height: 16px;
  letter-spacing: 0.4px;
  font-size: 1.125rem;
  display: flex !important;
  align-items: center !important;
 font-family: Open Sans, sans-serif;
}
.mat-date-range-input-start-wrapper {
  min-width: 8rem !important;
}
.mat-date-range-input-end-wrapper {
  color: #121112;
  pointer-events: none;
  box-shadow: none;
  font-weight: 600;
  font-family: Open Sans;
  line-height: 16px;
  letter-spacing: 0.4px;
  font-size: 1.125rem;
  display: flex !important;
  align-items: center !important;
}
.mat-date-range-input-separator {
  margin-right: 0.6rem !important;
}
.ctti-form-control .mat-select {
  display: block;
}

.ctti-dropdown:read-write:not(:placeholder-shown) {
  box-shadow: 0 0 0 1px #121112;
  border-color: #121112;
  color: #121112;
}
@media (min-width: 768px) {
  .ctti-dropdown {
    font-size: 1.125rem;
    line-height: 26px;
  }
}
.ctti-dropdown::placeholder {
  font-weight: 400;
  color: #5d5760;
  opacity: 1;
}
.ctti-dropdown::-moz-placeholder {
  font-weight: 400;
  color: #5d5760;
  opacity: 1;
}
.ctti-dropdown::-webkit-input-placeholder {
  font-weight: 400;
  color: #5d5760;
  opacity: 1;
}
.ctti-dropdown:hover {
  box-shadow: 0 4px 8px #1e000052;
  border: 1px solid #C00000;
}
.ctti-dropdown:focus,
.ctti-dropdown:focus-visible {
  box-shadow: 0 0 0 1px #121112;
  border-color: #121112;
  outline: none;
}
.ctti-dropdown[aria-disabled="true"] {
  background-color: #f9f7f9;
  border-color: #d8d4d9;
  color: #d8d4d9;
  pointer-events: none;
}
.ctti-dropdown[aria-disabled="true"] .mat-select-placeholder {
  color: #d8d4d9;
}
.ctti-dropdown--invalid {
  border-color: #ea0003;
  outline: none;
  border: solid 2px #ea0003;
}
.ctti-dropdown--invalid:hover {
  box-shadow: 0 4px 8px #1e000052;
  border: solid 2px #ea0003;
}
.ctti-dropdown--invalid:after {
  font-family: Material Icons;
  content: "warning_amber";
  position: absolute;
  font-size: 20px;
  color: #ea0003;
  top: 10px;
  right: 42px;
  z-index: 0;
  pointer-events: none;
  -webkit-font-feature-settings: "liga";
  font-weight: 100;
}
.ctti-dropdown--readonly {
  background-color: #f9f7f9;
  border-color: #d8d4d9;
  color: #121112;
  pointer-events: none;
}
.ctti-dropdown--readonly:before {
  color: #d8d4d9;
}
.ctti-dropdown:before {
  font-family: Material Icons;
  content: "expand_more";
  position: absolute;
  font-size: 24px;
  right: 10px;
  top: 10px;
  -webkit-font-feature-settings: "liga";
  font-weight: 400;
}
.ctti-dropdown .mat-select-trigger {
  padding: 10px 0;
  height: 100%;
}
.ctti-dropdown .mat-select-placeholder {
  color: #5d5760;
  font-weight: 400;
}
.ctti-dropdown .mat-select-arrow {
  visibility: hidden;
}
.ctti-dropdown .ctti-invalid-icon:before {
  padding-right: 0;
  right: 35px;
}
.ctti-dropdown.mat-select:not(.mat-select-empty) {
  box-shadow: 0 0 0 1px #121112;
  border-color: #121112;
  outline: none;
}
.ctti-dropdown-panel-multiple {
  min-width: calc(100% + 32px) !important;
}
.ctti-dropdown-panel {
  margin-top: 10px;
  background-color: white;
  border: 1px solid #C00000;
  box-shadow: 0 4px 8px #1e000052;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}
.ctti-dropdown-panel .mat-option {
  font-size: 1rem;
  line-height: 24px;
  font-family: Open Sans, sans-serif;
  color: #5d5760;
  color: #121112;
}
@media (min-width: 768px) {
  .ctti-dropdown-panel .mat-option {
    font-size: 1.125rem;
    line-height: 26px;
  }
}
.ctti-dropdown-panel .mat-option:hover:not(.mat-option-disabled),
.ctti-dropdown-panel .mat-option:focus:not(.mat-option-disabled) {
  background: #fbf1f1;
}
.ctti-dropdown-panel .mat-option.mat-selected {
  background-color: #fff;
}
.ctti-dropdown-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  color: #C00000;
  background-color: #fff;
  font-weight: 600;
}
.ctti-dropdown-panel .mat-option.mat-selected:not(.mat-option-multiple):before {
  font-family: Material Icons;
  content: "check";
  position: absolute;
  font-size: 20px;
  color: #C00000;
  right: 15px;
  top: 0;
  -webkit-font-feature-settings: "liga";
}
.ctti-dropdown-panel .mat-pseudo-checkbox {
  width: 24px;
  height: 24px;
  background-color: #fff;
  border: 2px solid #121112;
}
.ctti-dropdown-panel .mat-pseudo-checkbox-checked {
  background-color: #C00000;
  border: 2px solid #C00000;
}
.ctti-dropdown-panel .mat-pseudo-checkbox-checked:after {
  visibility: hidden;
}
.ctti-dropdown-panel .mat-pseudo-checkbox-checked:before {
  content: "";
  position: absolute;
  left: 7px;
  top: 1px;
  width: 7px;
  height: 15px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.mat-select-value-text {
  font-weight: 600;
}
.dropdown-resum {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #5d5760;
  font-family: Open Sans, "sans-serif";
}
.ctti-form-control {
  position: relative;
  display: block;
}

// .ctti-input-text {
//   font-size: 1.125rem;
//   line-height: 26px;
//   outline: none;
// }

// .ctti-input-text::placeholder {
//   font-weight: 400;
//   color: #5d5760;
//   opacity: 1;
// }
// .ctti-input-text::-moz-placeholder {
//   font-weight: 400;
//   color: #5d5760;
//   opacity: 1;
// }
// .ctti-input-text::-webkit-input-placeholder {
//   font-weight: 400;
//   color: #5d5760;
//   opacity: 1;
// }
// .ctti-input-text:disabled {
//   background-color: #f9f7f9;
//   border-color: #d8d4d9;
//   color: #d8d4d9;
//   pointer-events: none;
// }
// .ctti-input-text:disabled::placeholder {
//   font-weight: 400;
//   color: #d8d4d9;
// }
// .ctti-input-text:disabled::-moz-placeholder {
//   font-weight: 400;
//   color: #d8d4d9;
// }
// .ctti-input-text:disabled::-webkit-input-placeholder {
//   font-weight: 400;
//   color: #d8d4d9;
// }
// .ctti-input-text:read-only,
// .ctti-input-text:read-only:hover,
// .ctti-input-text:read-only:focus {
//   background-color: #f9f7f9;
//   border-color: #d8d4d9;
//   color: #121112;
//   pointer-events: none;
//   box-shadow: none;
// }
// .ctti-input-text:hover {
//   box-shadow: 0 4px 8px #1e000052;
//   border-color: #C00000;
// }
// .ctti-input-text:focus {
//   box-shadow: 0 0 0 1px #121112;
//   border-color: #121112;
//   color: #121112;
// }

// .ctti-input-text[type='text']:read-write:not(:placeholder-shown), .ctti-input-text[type='number']:read-write:not(:placeholder-shown) {
//   box-shadow: 0 0 0 1px #121112;
//   border-color: #121112;
//   color: #121112;
// }

// .ctti-input-text:focus-visible {
//   outline: none;
// }
// .ctti-input-text:required {
//   border-color: #121112;
// }
.input-resum {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #5d5760;
  font-family: Open Sans, "sans-serif";
}
.ctti-checkbox {
  -webkit-appearance: none;
  appearance: none;
  width: 24px;
  height: 24px;
  background-color: #fff;
  border: 2px solid #121112;
  box-sizing: border-box;
  position: relative;
  display: block;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  cursor: pointer;
}
.ctti-checkbox:hover {
  background-color: #fbf1f1;
  border: 2px solid #C00000;
}
.ctti-checkbox:focus-visible {
  outline: none;
}
.ctti-checkbox:focus:not(:active),
.ctti-checkbox:focus-within:not(:active) {
  
  outline-offset: 0.125rem;
}
.ctti-checkbox:checked {
  background-color: #C00000;
  border: 2px solid #C00000;
}
.ctti-checkbox:checked:before {
  content: "";
  position: absolute;
  left: 7px;
  top: 1px;
  width: 7px;
  height: 15px;
  border-style: solid;
  border-color: #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.ctti-checkbox:disabled {
  background-color: #d8d4d9;
  border-color: #bcb9bd;
  color: #d8d4d9;
  pointer-events: none;
}
.ctti-checkbox:disabled:checked:before {
  border-color: #5d5760;
}
.ctti-checkbox:disabled + label {
  color: #d8d4d9;
}
.ctti-checkbox__indeterminate {
  background-color: #C00000;
  border: 2px solid #C00000;
}
.ctti-checkbox__indeterminate:before {
  content: "";
  position: absolute;
  left: 4px;
  top: 9px;
  width: 12px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
}
.ctti-checkbox__indeterminate:hover {
  background-color: #C00000;
  border: 2px solid #C00000;
}
.ctti-modal__close {
  border: 0;
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background: transparent;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 6;
  cursor: pointer;
}
.ctti-modal__actions {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  min-height: 52px;
  align-items: center;
  box-sizing: content-box;
}
@media (max-width: 767px) {
  .ctti-modal__actions button {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .ctti-modal__actions {
    flex-direction: row;
    justify-content: flex-end;
  }
}
.ctti-modal .mat-dialog-container {
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
}
.ctti-modal .icon-modal {
  color: #5d5760;
  font-size: 2.75rem;
  padding: 1.3rem 0rem;
}
.ctti-modal .close:hover {
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  background-color: #e7e4e4;
}
.ctti-table.sticky-options {
  overflow-x: auto;
}
.ctti-table tbody {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}
.ctti-table tbody tr {
  height: 56px;
  background-color: #fff;
}
.ctti-table tbody tr:hover {
  background-color: #f9f7f9;
}
.ctti-table tbody td,
.ctti-table tbody th {
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 16px;
}
.ctti-table tbody td:last-of-type {
  padding-right: 16px;
}
.ctti-table tr:hover {
  background-color: #f9f7f9;
}
.ctti-table tr:focus,
.ctti-table tr:focus-within {
  background-color: #f9f7f9;
}
.ctti-table tr:focus td,
.ctti-table tr:focus-within td {
  border-bottom-color: #0080fa;
}
.ctti-table td {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #d8d4d9;
  font-size: 0.875rem;
  line-height: 20px;
  font-family: Open Sans, sans-serif;
  color: #5d5760;
  height: 56px;
}
@media (min-width: 768px) {
  .ctti-table td {
    font-size: 1rem;
    line-height: 26px;
  }
}
.ctti-table td + td:after {
  content: "";
  height: 1rem;
  width: 0.063rem;
  position: absolute;
  left: 0;
  top: 1.188rem;
  background-color: #d8d4d9;
}
.ctti-table--active {
  background-color: #fbf1f1;
}
.ctti-table--active td {
  color: #C00000;
  border-bottom-color: #C00000;
}
.ctti-table--disabled {
  background-color: #fff;
  pointer-events: none;
}
.ctti-table--disabled td {
  color: #d8d4d9;
  border-bottom-color: #d8d4d9;
}
.ctti-paginator {
  background-color: #f9f7f9;
}
@media (max-width: 767px) {
  .ctti-paginator {
    padding-top: 10px;
  }
}
.ctti-paginator * {
  color: #121112;
}
.ctti-paginator .mat-form-field-underline {
  background-color: #121112;
}
.ctti-paginator button + button {
  margin-left: 8px;
}
.ctti-table + .ctti-paginator {
  border-radius: 0 0 4px 4px !important;
  -webkit-border-radius: 0 0 4px 4px !important;
  -moz-border-radius: 0 0 4px 4px !important;
}
.mat-sort-header-container:before {
  content: "unfold_more";
  font-family: Material Icons;
  font-size: 16px;
  left: 0;
}
.th-first-with {
  width: 56px;
}
.th-last-with {
  width: 120px;
}
.position-sticky {
  position: sticky;
  right: 0;
  background-color: #fff;
}
.position-sticky:after {
  content: "";
  height: 1rem;
  position: absolute;
  left: 0;
  top: 1.188rem;
  background-color: #d8d4d9 !important;
  height: 100% !important;
  top: 0 !important;
}
.ctti-textarea {
  min-width: 280px;
  min-height: 104px;
  padding-top: 10px;
  width: 100%;
  height: 48px;
  display: flex;
  background-color: #fff;
  border-style: solid;
  border-width: 1px;
  border-color: #121112;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 1rem;
  line-height: 24px;
  font-weight: 600;
  font-family: Open Sans, sans-serif;
  box-sizing: border-box;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  position: relative;
}
@media (min-width: 768px) {
  .ctti-textarea {
    font-size: 1.125rem;
    line-height: 26px;
  }
}
.ctti-textarea::placeholder {
  font-weight: 400;
  color: #5d5760;
  opacity: 1;
}
.ctti-textarea::-moz-placeholder {
  font-weight: 400;
  color: #5d5760;
  opacity: 1;
}
.ctti-textarea::-webkit-input-placeholder {
  font-weight: 400;
  color: #5d5760;
  opacity: 1;
}
.ctti-textarea:disabled {
  background-color: #f9f7f9;
  border-color: #d8d4d9;
  color: #d8d4d9;
  pointer-events: none;
}
.ctti-textarea:disabled::placeholder {
  font-weight: 400;
  color: #d8d4d9;
}
.ctti-textarea:disabled::-moz-placeholder {
  font-weight: 400;
  color: #d8d4d9;
}
.ctti-textarea:disabled::-webkit-input-placeholder {
  font-weight: 400;
  color: #d8d4d9;
}
.ctti-textarea:read-only,
.ctti-textarea:read-only:hover,
.ctti-textarea:read-only:focus {
  background-color: #f9f7f9;
  border-color: #d8d4d9;
  color: #121112;
  pointer-events: none;
  box-shadow: none;
}
.ctti-textarea:hover {
  box-shadow: 0 4px 8px #1e000052;
  border-color: #C00000;
}
.ctti-textarea:focus {
  box-shadow: 0 0 0 1px #121112;
  border-color: #121112;
  color: #121112;
}
.ctti-textarea:read-write:not(:placeholder-shown) {
  box-shadow: 0 0 0 1px #121112;
  border-color: #121112;
  color: #121112;
}
.ctti-textarea:focus-visible {
  outline: none;
}
.ctti-textarea:required {
  border-color: #121112;
}
.ctti-textarea:invalid {
  box-shadow: 0 0 0 1px #ea0003 !important;
  border-color: #ea0003 !important;
}
.ctti-header {
  height: 48px;
  background: #5d5760;
  display: flex;
  align-items: center;
}
.top {
  margin-top: 1rem;
}
.ctti-radio {
  width: 24px;
  height: 24px;
  border: 2px solid #121112;
  box-sizing: border-box;
  -webkit-appearance: none;
  appearance: none;
  position: relative;
  background-color: #fff;
  margin-right: 0.5rem;
  border-radius: 24px;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  cursor: pointer;
}
.ctti-radio:checked {
  border: 2px solid #C00000;
}
.ctti-radio:checked:before {
  content: "";
  position: absolute;
  width: 15px;
  height: 14px;
  left: 2px;
  top: 3px;
  border-radius: 22px;
  -webkit-border-radius: 22px;
  -moz-border-radius: 22px;
  background-color: #C00000;
}
.ctti-radio:hover {
  background-color: #fbf1f1;
  border: 2px solid #C00000;
}
.ctti-radio:active {
  background-color: #fff;
}
.ctti-radio:focus,
.ctti-radio:focus-within {
  
  outline-offset: 0.125rem;
}
.ctti-radio:disabled {
  background-color: #d8d4d9;
  border-color: #bcb9bd;
  color: #d8d4d9;
  pointer-events: none;
}
.ctti-radio:disabled + label {
  color: #d8d4d9;
}
.radio-button .ctti-label:hover {
  cursor: pointer;
}
.ctti-radio:disabled:checked:before {
  background-color: #5d5760;
  width: 14px;
  height: 15px;
  left: 3px;
  top: 3px;
}
.mat-radio-outer-circle {
  border-color: #121112;
}
#mat-menu-panel-2{
  background-color: #FFF !important;
  margin-top: 10px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius:  8px;
}
.mat-ripple-element {
  background-color: #C0000021 !important;
}
.mat-radio-outer-circle:hover {
  border: 2px solid #C00000;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #C00000;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #C00000;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple {
  background-color: #C000005d;
}
.mat-radio-label-content {
  font-family: Open Sans, Courier, monospace;
  color: #121112 !important;
}
.mat-radio-disabled {
  cursor: no-drop !important;
}
.mat-radio-disabled .mat-radio-outer-circle {
  border: 2px solid #d8d4d9 !important;
}
.mat-radio-disabled span {
  color: #d8d4d9 !important;
  cursor: no-drop !important;
}
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: #5d5760 !important;
}
.mat-radio-disabled .mat-radio-outer-circle {
  background-color: #d8d4d9 !important;
}
.mat-radio-disabled .mat-radio-outer-circle {
  border: 2px solid #bcb9bd !important;
}
.ctti-switch {
  position: relative;
  display: flex;
  align-items: center;
}
.ctti-switch input[type="checkbox"] {
  opacity: 0;
  width: 0;
  height: 0;
}
.ctti-switch__slider {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  inset: 0;
  background-color: #f9f7f9;
  border: 2px solid #121112;
  transition: 0.4s;
  border-radius: 24px;
  width: 48px;
  height: 24px;
}
.ctti-switch__slider:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  left: -2px;
  bottom: -2px;
  background-color: #fff;
  border: 2px solid #121112;
  transition: 0.4s;
  border-radius: 50%;
}
input:hover + .ctti-switch__slider {
  border: 2px solid #C00000;
  background-color: #f9f7f9;
}
input:hover + .ctti-switch__slider:before {
  border: 2px solid #C00000;
  background-color: #fff;
}
input:checked + .ctti-switch__slider {
  background-color: #afe076;
  border: 2px solid #121112;
}
input:checked:hover + .ctti-switch__slider {
  border: 2px solid #C00000;
}
input:checked:hover + .ctti-switch__slider:before {
  border: 2px solid #C00000;
}
input:focus + .ctti-switch__slider {
  
  outline-offset: 0.125rem;
}
input:checked + .ctti-switch__slider:before {
  left: -4px;
  background-color: #458200;
  transform: translate(26px);
}
input:disabled + .ctti-switch__slider {
  border: 2px solid #bcb9bd;
  background-color: #d8d4d9;
  pointer-events: none;
}
input:disabled + .ctti-switch__slider:before {
  background-color: #d8d4d9;
  border: 2px solid #bcb9bd;
}
input:disabled + .ctti-switch__slider + .ctti-switch__label {
  color: #d8d4d9;
}
.ctti-tooltip {
  background-color: #2c2c2c;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #fff;
  margin: 20px 14px !important;
  padding: 8px 16px !important;
  box-shadow: 0 4px 8px 2px #1e000066;
  border-radius: 12px;
}
// .ctti-tooltip {
//   background-color: #333333;
//   font-family: Open Sans;
//   font-style: normal;
//   font-weight: 400;
//   font-size: 12px;
//   line-height: 16px;
//   letter-spacing: 0.4px;
//   color: #fff;
//   margin: 20px 14px !important;
//   padding: 8px 16px !important;
//   box-shadow: 0 4px 8px 2px #1e000066;
// }
// .ctti-tooltip .bc {
//   white-space: pre-line;
// }
// .ctti-tooltip-bc {
//   font-family: Open Sans;
//   font-style: normal;
//   font-weight: 400;
//   font-size: 12px;
//   line-height: 16px;
//   letter-spacing: 0.4px;
//   color: #fff;
//   margin: 1px !important;
//   padding: 8px 16px !important;
//   box-shadow: 0 4px 8px 2px #1e000066;
//   white-space: pre-line;
// }
.ctti-list__item {
  height: 48px;
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d8d4d9;
  padding-left: 16px;
  padding-right: 16px;
  background-color: #fff;
  font-size: 1rem;
  line-height: 24px;
  font-family: Open Sans, sans-serif;
  color: #5d5760;
}
@media (min-width: 768px) {
  .ctti-list__item {
    font-size: 1.125rem;
    line-height: 26px;
  }
}
.ctti-list__item:hover {
  background-color: #f9f7f9;
}
.ctti-list__item:active {
  border-top: 1px solid #C00000;
  border-bottom: 1px solid #C00000;
  background-color: #fff;
  color: #C00000;
}
.ctti-list__item:focus:not(.ctti-list__item:active) {
  background-color: #f9f7f9;
  border-top: 1px solid #0080fa;
  border-bottom: 1px solid #0080fa;
  outline: 0;
  outline-offset: 0;
}
.ctti-list__item:focus-visible {
  outline: none;
}
.ctti-list__item--disabled {
  color: #d8d4d9;
  pointer-events: none;
}
.ctti-tabs.mat-tab-group .mat-ink-bar {
  background-color: #C00000;
}
.ctti-tabs.mat-tab-group .mat-tab-label {
  position: relative;
  opacity: 1;
  font-size: 1rem;
  line-height: 24px;
  font-family: Open Sans, sans-serif;
  color: #5d5760;
  font-weight: 600;
  border-width: 2px;
  border-style: solid;
  border-color: #fff;
  border-bottom-color: #d8d4d9;
}
@media (min-width: 768px) {
  .ctti-tabs.mat-tab-group .mat-tab-label {
    font-size: 1.125rem;
    line-height: 26px;
  }
}
.ctti-tabs.mat-tab-group .mat-tab-label:hover {
  color: #C00000;
  background: #fbf1f1;
  border: 2px solid #C00000;
  opacity: 1;
  z-index: 1;
}
.ctti-tabs.mat-tab-group .mat-tab-label.mat-tab-label-active {
  color: #C00000;
}
.ctti-tabs.mat-tab-group
  .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled) {
  
  border-bottom: 2px solid #0080fa;
  outline-offset: -1px;
  background-color: transparent;
  z-index: 1;
}
.ctti-tabs.mat-tab-group .mat-tab-header {
  border-bottom: none;
}
.ctti-tabs.mat-tab-group .mat-tab-disabled {
  pointer-events: none;
  color: #d8d4d9 !important;
  border-bottom-color: #f9f7f9;
}
.ctti-tabs-container.mat-tab-group .mat-ink-bar {
  top: 0;
}
.ctti-tabs-container.mat-tab-group .mat-tab-label {
  position: relative;
  opacity: 1;
  font-size: 1rem;
  line-height: 24px;
  font-family: Open Sans, sans-serif;
  color: #5d5760;
  font-weight: 600;
  background-color: #eae6eb;
  border-top-width: 3px;
  border-top-style: solid;
  border-top-color: #121112;
}
@media (min-width: 768px) {
  .ctti-tabs-container.mat-tab-group .mat-tab-label {
    font-size: 1.125rem;
    line-height: 26px;
  }
}
.ctti-tabs-container.mat-tab-group
  .mat-tab-label:hover:not(.mat-tab-label-active) {
  color: #C00000;
  background-color: #fbf1f1;
  border-top-color: #C00000;
  opacity: 1;
  z-index: 1;
}
.ctti-tabs-container.mat-tab-group
  .mat-tab-label:hover:not(.mat-tab-label-active):before {
  content: "";
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 100%;
  border-right: 2px solid #C00000;
  border-bottom: 2px solid #C00000;
  border-left: 2px solid #C00000;
}
.ctti-tabs-container.mat-tab-group .mat-tab-label.mat-tab-label-active {
  background-color: #fff;
  color: #C00000;
  border-color: #fff;
}
.ctti-tabs-container.mat-tab-group .mat-tab-header {
  border: none;
}
.ctti-tabs-container.mat-tab-group .mat-tab-disabled {
  pointer-events: none;
  color: #d8d4d9 !important;
  background-color: #f9f7f9;
  border-color: #f9f7f9;
}
.ctti-tabs-container.mat-tab-group
  .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled) {
  
  outline-offset: -1px;
  background-color: transparent;
  z-index: 1;
  background-color: #eae6eb;
}
.ctti-tabs-container.mat-tab-group
  .mat-tab-label.mat-tab-label-active.cdk-keyboard-focused:not(
    .mat-tab-disabled
  ) {
  border-color: #C00000;
  color: #121112;
}
.ctti-header {
  background-color: #5d5760;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  width: 100%;
}
.ctti-header__logo {
  display: flex;
  align-items: center;
}
.ctti-header__profile {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  line-height: 20px;
  font-family: Open Sans, sans-serif;
  color: #5d5760;
  color: #fff;
}
@media (min-width: 768px) {
  .ctti-header__profile {
    font-size: 1rem;
    line-height: 26px;
  }
}
.ctti-header .container {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
}
.ctti-header-generic {
  background-color: #5d5760;
  max-width: 82.5rem;
}
.ctti-header-generic .container {
  display: flex;
  padding: 0.75rem 1.5rem;
  justify-content: space-between;
}
.ctti-header-generic .ctti-header__profile .mat-button-wrapper {
  display: flex;
  align-items: center;
}
@media (max-width: 552px) {
  .ctti-header-generic .ctti-header__profile .button-header {
    padding-right: 0;
    font-size: 1.1rem !important;
  }
}
.ctti-header-generic .ctti-header__profile .icon-person {
  font-size: 1rem;
  border-left: 1px solid;
  padding-left: 1.3rem;
}
.ctti-header-generic .ctti-header__profile .icon {
  font-size: 1.25rem;
  padding-left: 1rem;
  cursor: pointer;
}
.ctti-header-generic .ctti-header__profile .icon-more {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ctti-header-generic .ctti-header__profile .name {
  border-top: 1px solid #959198;
  padding: 0rem 1rem;
}
.ctti-header-generic .ctti-header__profile .btn-header {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
 font-family: Open Sans, sans-serif;
}
.customize + * .mat-menu-panel {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-family: Open Sans;
  background: #5d5760 !important;
}
.customize + * .mat-menu-panel button {
  color: #fff !important;
}
.name-btn {
  border-top: 1px solid #959198 !important;
  padding: 0rem 1rem;
}
.logout {
  display: none !important;
}
@media (max-width: 552px) {
  .logout {
    display: flex !important;
    align-items: center;
    place-content: space-between;
  }
}
.btn-header {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
 font-family: Open Sans, sans-serif;
}
.ctti-footer {
  display: flex;
  height: 72px;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #5d5760;
}
.ctti-footer .container {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
}
.ctti-footer__logos {
  flex-wrap: wrap;
}
.ctti-footer__logos .img-footer {
  margin-right: 2rem;
}
.ctti-footer__e-social {
  color: #fff;
  font-weight: 600;
  font-size: 1.12rem;
}
.ctti-footer__link {
  font-size: 1rem;
  line-height: 24px;
  font-family: Open Sans, sans-serif;
  color: #5d5760;
  color: #fff;
  text-decoration: underline;
  text-underline-offset: 5px;
}
@media (min-width: 768px) {
  .ctti-footer__link {
    font-size: 1.125rem;
    line-height: 26px;
  }
}
.ctti-footer__link:hover {
  color: #fff;
  text-decoration: underline;
  text-decoration-thickness: 3px;
}
.ctti-chip {
  position: relative;
  display: flex;
  align-items: center;
}
.ctti-chip input[type="checkbox"] {
  opacity: 0;
  width: 0;
  height: 0;
}
.ctti-chip__label {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  min-width: 72px;
  height: 40px;
  background: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-color: #121112;
  box-sizing: border-box;
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;
  color: #121112;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  cursor: pointer;
}
input:hover + .ctti-chip__label {
  border-color: #C00000;
  color: #C00000;
  background-color: #fbf1f1;
}
input:checked + .ctti-chip__label {
  border-color: #C00000;
  color: #fff;
  background-color: #C00000;
}
input:disabled + .ctti-chip__label {
  border-color: #d8d4d9;
  color: #d8d4d9;
  background-color: #f9f7f9;
  pointer-events: none;
}
input:focus-visible + .ctti-chip__label {
  border-color: #121112;
  
  outline-offset: 0.125rem;
}
input:checked:focus-visible + .ctti-chip__label {
  border-color: #C00000;
  
  outline-offset: 0.125rem;
}
.ctti-nav {
  width: 100%;
  height: 64px;
  background-color: #f9f7f9;
  box-shadow: 0 3px 5px #c3bbbb;
  text-decoration: none;
  cursor: pointer;
}
.ctti-nav .container {
  height: 100%;
}
.ctti-nav__menu {
  height: 100%;
  display: inline-flex;
  margin-bottom: 0;
}
.ctti-nav__item {
  height: 100%;
}
.ctti-nav button {
  -webkit-appearance: none;
  appearance: none;
  border: 0;
}
.ctti-nav__link,
.ctti-nav__link.mat-menu-item {
  font-size: 1rem;
  line-height: 24px;
  font-family: Open Sans, sans-serif;
  color: #5d5760;
  font-weight: 600;
  color: #121112;
  height: 100%;
  display: inline-flex;
  align-items: center;
  position: relative;
  padding-left: 24px;
  padding-right: 24px;
  border-radius: 0;
  background: #f9f7f9;
  z-index: 1;
}
@media (min-width: 768px) {
  .ctti-nav__link,
  .ctti-nav__link.mat-menu-item {
    font-size: 1.125rem;
    line-height: 26px;
  }
}
.ctti-nav__link:before,
.ctti-nav__link.mat-menu-item:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: #f9f7f9;
}
.ctti-nav__link:hover,
.ctti-nav__link.mat-menu-item:hover {
  outline: none;
  background: white;
  color: #C00000;
}
.ctti-nav__link:hover:before,
.ctti-nav__link.mat-menu-item:hover:before {
  background-color: #C00000;
}
.ctti-nav__link[aria-expanded="true"],
.ctti-nav__link:active,
.ctti-nav__link--active.mat-menu-item,
.ctti-nav__link.mat-menu-item[aria-expanded="true"],
.ctti-nav__link.mat-menu-item:active,
.ctti-nav__link.mat-menu-item--active.mat-menu-item {
  outline: none;
  background: transparent;
  color: #C00000;
}
.ctti-nav__link[aria-expanded="true"]:before,
.ctti-nav__link:active:before,
.ctti-nav__link--active.mat-menu-item:before,
.ctti-nav__link.mat-menu-item[aria-expanded="true"]:before,
.ctti-nav__link.mat-menu-item:active:before,
.ctti-nav__link.mat-menu-item--active.mat-menu-item:before {
  height: 4px;
  background-color: #C00000;
}
.ctti-nav__link:focus,
.ctti-nav__link.mat-menu-item:focus {
  outline: 0;
}
.ctti-nav__link:focus-visible,
.ctti-nav__link.mat-menu-item:focus-visible {
  background: transparent;
  outline: 0;
}
.ctti-nav__link:focus-visible:before,
.ctti-nav__link.mat-menu-item:focus-visible:before {
  background-color: transparent;
  height: 100%;
  border-top: 1px solid #0080fa;
  border-left: 1px solid #0080fa;
  border-right: 1px solid #0080fa;
  border-bottom: 2px solid #C00000;
}
.ctti-nav__link[aria-haspopup="true"],
.ctti-nav__link.mat-menu-item[aria-haspopup="true"] {
  padding-right: 48px;
}
.ctti-nav__link[aria-haspopup="true"]:after,
.ctti-nav__link.mat-menu-item[aria-haspopup="true"]:after {
  content: "arrow_drop_down";
  font-family: Material Icons;
  position: absolute;
  -webkit-font-feature-settings: "liga";
  right: 20px;
  font-size: 20px;
}
.ctti-nav__link[aria-expanded="true"]:after,
.ctti-nav__link.mat-menu-item[aria-expanded="true"]:after {
  content: "arrow_drop_up";
}
.ctti-nav__link--disabled.mat-menu-item,
.ctti-nav__link--disabled,
.ctti-nav__link:disabled,
.ctti-nav__link.mat-menu-item--disabled.mat-menu-item,
.ctti-nav__link.mat-menu-item--disabled,
.ctti-nav__link.mat-menu-item:disabled {
  color: #d8d4d9;
  cursor: not-allowed;
  pointer-events: none;
}
.ctti-nav__menu-panel.mat-menu-panel {
  background-color: #f9f7f9;
  min-width: 260px;
  box-shadow: 0 3px 5px #c3bbbb;
  border: 1px solid #d8d4d9;
  border-radius: 0;
  padding: 0;
}
.ctti-nav__sub-item {
  padding: 0;
  height: 100%;
}
.ctti-nav__sub-link,
.ctti-nav__sub-link.mat-menu-item {
  font-size: 1rem;
  line-height: 24px;
  font-family: Open Sans, sans-serif;
  color: #5d5760;
  color: #121112;
  height: 56px;
  width: 100%;
  display: inline-flex;
  align-items: center;
  position: relative;
  padding-left: 24px;
  padding-right: 24px;
}
@media (min-width: 768px) {
  .ctti-nav__sub-link,
  .ctti-nav__sub-link.mat-menu-item {
    font-size: 1.125rem;
    line-height: 26px;
  }
}
.ctti-nav__sub-link:before,
.ctti-nav__sub-link.mat-menu-item:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 4px;
  height: 100%;
  background-color: #f9f7f9;
}
.ctti-nav__sub-link:hover,
.ctti-nav__sub-link.mat-menu-item:hover {
  outline: none;
  background: white;
  color: #C00000;
}
.ctti-nav__sub-link:hover:before,
.ctti-nav__sub-link.mat-menu-item:hover:before {
  background-color: #fff;
}
.ctti-nav__sub-link:active,
.ctti-nav__sub-link--active.mat-menu-item,
.ctti-nav__sub-link.mat-menu-item:active,
.ctti-nav__sub-link.mat-menu-item--active.mat-menu-item {
  outline: none;
  background: transparent;
  color: #C00000;
  font-weight: 600;
}
.ctti-nav__sub-link:active:before,
.ctti-nav__sub-link--active.mat-menu-item:before,
.ctti-nav__sub-link.mat-menu-item:active:before,
.ctti-nav__sub-link.mat-menu-item--active.mat-menu-item:before {
  background-color: #C00000;
}
.ctti-nav__sub-link:focus,
.ctti-nav__sub-link.mat-menu-item:focus {
  outline: 0;
}
.ctti-nav__sub-link:focus-visible,
.ctti-nav__sub-link.mat-menu-item:focus-visible {
  background: transparent;
  outline: 0;
}
.ctti-nav__sub-link:focus-visible:before,
.ctti-nav__sub-link.mat-menu-item:focus-visible:before {
  background-color: transparent;
  width: 100%;
  border-top: 1px solid #0080fa;
  border-left: 1px solid #0080fa;
  border-right: 1px solid #0080fa;
  border-bottom: 1px solid #0080fa;
}
@media (max-width: 568px) {
  .mobile {
    height: 3rem !important;
  }
}
@media (max-width: 568px) {
  .menu-nav .view-desk {
    display: none;
  }
}
@media (min-width: 567px) {
  .menu-nav .menu-mobile {
    display: none;
  }
}
.menu-nav .menu-mobile .menu-mobile__position {
  color: #5d5760;
  height: 2.5rem;
  display: flex;
  align-items: center;
  font-family: Open Sans;
}
.menu-nav .menu-mobile .menu-mobile__position .menu-mobile__btn {
  display: flex;
  align-items: center;
  border-bottom: 3px solid #5d5760;
  background: #f9f7f9;
}
.menu-nav .menu-mobile .menu-mobile__position .menu-mobile__btn .icon {
  font-size: 1.3rem;
  margin-top: 0.2rem;
  color: #5d5760;
  padding-left: 2px;
  background: #f9f7f9;
}
.menu-nav .menu-mobile__menu {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: #5d5760a8;
  z-index: 7;
}
.menu-nav .menu-mobile__menu .menu-mobile__menu-items {
  background-color: #fff;
  width: 60%;
  height: 100vw;
  box-shadow: 0 -1px 5px #00000034;
}
.menu-nav .menu-mobile__menu .menu-mobile__menu-items .close-position {
  display: flex;
  justify-content: end;
}
.menu-nav .menu-mobile__menu .menu-mobile__menu-items .icon {
  cursor: pointer;
  padding: 20px 15px;
}
.menu-nav .menu-mobile__menu .menu-mobile__menu-items .content-items {
  padding: 0rem 0.3rem;
}
.menu-nav .menu-mobile__menu .menu-mobile__menu-items .items {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  padding: 1rem;
  border-bottom: 1px solid #d1d1d1;
}
.ctti-notification {
  width: 100%;
  min-height: 100px;
  padding: 16px 56px;
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px 2px #1e000066;
}
.ctti-notification:before {
  font-family: Material Icons Outlined;
  content: "";
  position: absolute;
  top: 12px;
  left: 16px;
  font-size: 24px;
  -webkit-font-feature-settings: "liga";
}
.ctti-notification:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 4px;
  height: 100%;
}
.ctti-notification p {
  color: #121112;
  margin-bottom: 0;
}
.ctti-notification__close {
  border: 0;
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background: transparent;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 6;
}
.ctti-notification button {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0;
  background-color: transparent;
}
.ctti-notification--success {
  background-color: #eef8e2;
}
.ctti-notification--success:before {
  content: "verified";
  color: #458200;
}
.ctti-notification--success:after {
  background-color: #458200;
}
.ctti-notification--success .ctti-notification__wrapper:before {
  border-color: #afe076 transparent transparent transparent;
}
.ctti-notification--info {
  background-color: #e3f1ff;
}
.ctti-notification--info:before {
  content: "info";
  color: #0080fa;
}
.ctti-notification--info:after {
  background-color: #0080fa;
}
.ctti-notification--info .ctti-notification__wrapper:before {
  border-color: #a1d1ff transparent transparent transparent;
}
.ctti-notification--alert {
  background-color: #fff2eb;
}
.ctti-notification--alert:before {
  content: "report";
  color: #ef6f25;
}
.ctti-notification--alert:after {
  background-color: #ef6f25;
}
.ctti-notification--alert .ctti-notification__wrapper:before {
  border-color: #f9cdb4 transparent transparent transparent;
}
.ctti-notification--error {
  background-color: #fff0f0;
}
.ctti-notification--error:before {
  content: "warning_amber";
  color: #ea0003;
}
.ctti-notification--error:after {
  background-color: #ea0003;
}
.ctti-notification--error .ctti-notification__wrapper:before {
  border-color: #ffa7a9 transparent transparent transparent;
}
.ctti-notification--light-success {
  background-color: #fff;
  box-shadow: none;
}
.ctti-notification--light-success h5 {
  color: #458200;
}
.ctti-notification--light-success:before {
  content: "verified";
  color: #458200;
}
.ctti-notification--light-success:after {
  background-color: #458200;
}
.ctti-notification--light-info {
  background-color: #fff;
  box-shadow: none;
}
.ctti-notification--light-info h5 {
  color: #005eb8;
}
.ctti-notification--light-info:before {
  content: "info";
  color: #0080fa;
}
.ctti-notification--light-info:after {
  background-color: #0080fa;
}
.ctti-notification--light-alert {
  background-color: #fff;
  box-shadow: none;
}
.ctti-notification--light-alert h5 {
  color: #c04700;
}
.ctti-notification--light-alert:before {
  content: "report";
  color: #ef6f25;
}
.ctti-notification--light-alert:after {
  background-color: #ef6f25;
}
.ctti-notification--light-error {
  background-color: #fff;
  box-shadow: none;
}
.ctti-notification--light-error h5 {
  color: #ea0003;
}
.ctti-notification--light-error:before {
  content: "warning_amber";
  color: #ea0003;
}
.ctti-notification--light-error:after {
  background-color: #ea0003;
}
.ctti-notification-multiple {
  background-color: #f9f7f9;
  position: relative;
  width: 100%;
  height: auto;
  padding: 24px 56px 24px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  box-shadow: 0 4px 8px 2px #1e000066;
}
.ctti-notification-multiple button {
  position: absolute;
  top: 24px;
  right: 16px;
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0;
  background-color: transparent;
}
.ctti-notification__close:hover {
  background-color: #38373812;
  border-radius: 4px;
}
.ctti-badge .mat-badge-content {
  position: relative;
  color: #fff;
  top: 0 !important;
  right: 0 !important;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 5px;
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  background: #C00000;
  border-radius: 16px;
  height: 16px;
}
.ctti-badge--lg .mat-badge-content {
  padding: 8px 24px;
  height: 32px;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.400000006px;
  text-align: left;
  font-family: Open Sans;
}
.ctti-badge--md .mat-badge-content {
  padding: 4px 16px;
  height: 24px;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.400000006px;
  text-align: left;
  font-family: Open Sans;
}
.ctti-badge--sm .mat-badge-content {
  padding: 0 5px;
  height: 16px;
  font-size: 10px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.400000006px;
  text-align: left;
  font-family: Open Sans;
}
.ctti-badge--xs .mat-badge-content {
  padding: 0;
  color: transparent;
  width: 8px;
  height: 8px;
}
.ctti-badge-outlined .mat-badge-content {
  background: #fbf1f1;
  color: #C00000;
}
.ctti-badge-success .mat-badge-content {
  background: #458200;
  color: #fff;
}
.ctti-badge-success-outlined .mat-badge-content {
  background: #eef8e2;
  color: #2c4510;
}
.ctti-badge-info .mat-badge-content {
  background: #005eb8;
  color: #fff;
}
.ctti-badge-info-outlined .mat-badge-content {
  background: #e3f1ff;
  color: #003a71;
}
.ctti-badge-alert .mat-badge-content {
  background: #c04700;
  color: #fff;
}
.ctti-badge-alert-outlined .mat-badge-content {
  background: #fff2eb;
  color: #7e3409;
}
.ctti-badge-error .mat-badge-content {
  background: #ea0003;
  color: #fff;
}
.ctti-badge-error-outlined .mat-badge-content {
  background: #fff0f0;
  color: #a20002;
}
.ctti-badge--bl .mat-badge-content {
  border: 2px solid #ffffff;
}
.ctti-badge--bd .mat-badge-content {
  border: 2px solid #5d5760;
}
.ctti-badge-icon--sm {
  position: relative;
}
.ctti-badge-icon--sm .mat-badge-content {
  position: absolute;
  top: -4px !important;
  right: -1px !important;
  width: 16px;
  padding: 0 5px;
  font-size: 10px;
  height: 16px;
}
.ctti-badge-icon--sm-multi {
  position: relative;
}
.ctti-badge-icon--sm-multi .mat-badge-content {
  display: block !important;
  position: absolute;
  top: -4px !important;
  right: -14px !important;
  width: 29px;
  padding: 0 5px;
  font-size: 10px;
  height: 16px;
}
.ctti-badge-icon--xs {
  position: relative;
}
.ctti-badge-icon--xs .mat-badge-content {
  color: transparent;
  position: absolute;
  top: 1px !important;
  right: 6px !important;
  width: 8px;
  height: 8px;
  padding: 0;
}
.ctti-badge-icon--xs--bl {
  position: relative;
}
.ctti-badge-icon--xs--bl .mat-badge-content {
  color: transparent;
  position: absolute;
  top: 0 !important;
  right: 4px !important;
  width: 10px;
  height: 10px;
  border: 2px solid #ffffff;
  padding: 0;
}
.ctti-badge-icon--sm--bl {
  position: relative;
}
.ctti-badge-icon--sm--bl .mat-badge-content {
  position: absolute;
  top: -5px !important;
  right: -2px !important;
  width: 18px;
  padding: 0 4px;
  font-size: 10px;
  height: 18px;
  border: 2px solid #ffffff;
}
.ctti-badge-icon--sm-multi--bl {
  position: relative;
}
.ctti-badge-icon--sm-multi--bl .mat-badge-content {
  display: block !important;
  position: absolute;
  top: -6px !important;
  right: -17px !important;
  width: 32px;
  padding: 0 2px;
  font-size: 10px;
  height: 20px;
  border: 2px solid #ffffff;
}
.ctti-badge-icon--xs--bd {
  position: relative;
}
.ctti-badge-icon--xs--bd .mat-badge-content {
  color: transparent;
  position: absolute;
  top: 0 !important;
  right: 4px !important;
  width: 10px;
  height: 10px;
  border: 2px solid #5d5760;
  padding: 0;
}
.ctti-badge-icon--sm--bd {
  position: relative;
}
.ctti-badge-icon--sm--bd .mat-badge-content {
  position: absolute;
  top: -5px !important;
  right: -2px !important;
  width: 18px;
  padding: 0 4px;
  font-size: 10px;
  height: 18px;
  border: 2px solid #5d5760;
}
.ctti-badge-icon--sm-multi--bd {
  position: relative;
}
.ctti-badge-icon--sm-multi--bd .mat-badge-content {
  display: block !important;
  position: absolute;
  top: -6px !important;
  right: -17px !important;
  width: 32px;
  padding: 0 2px;
  font-size: 10px;
  height: 20px;
  border: 2px solid #5d5760;
}
.light-border-badge-container {
  background-color: #5d5760;
  padding: 0.5em;
  border-radius: 5px;
}
.overlay {
  background-color: #C00000;
  height: 64px;
  width: 64px;
  border-radius: 8px;
  position: relative;
  margin: auto;
}
.mat-spinner {
  position: relative;
  margin: 20% auto auto;
}
.mat-spinner-color::ng-deep circle {
  stroke: #fff !important;
}
.mat-divider {
  border-top-color: ctti-neutral-30;
  border-top-width: 2px;
  display: block;
  margin: 0;
  border-top-style: solid;
  padding: 0.5rem;
}
.and-subheader {
  padding-top: 8px;
  padding-bottom: 8px;
}
.large-desktop {
  padding-top: 8px;
  padding-bottom: 8px;
  width: 424px;
}
.desktop {
  padding-top: 8px;
  padding-bottom: 8px;
  width: 364px;
}
.tablet-landscape {
  padding-top: 8px;
  padding-bottom: 8px;
  width: 304px;
}
.tablet-portrait {
  padding-top: 8px;
  padding-bottom: 8px;
  width: 168px;
}
.content_divider {
  width: 100%;
}
.breadcrumb {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
  height: 5vh;
}
.breadcrumb .list-breadcrumbs {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.breadcrumb .list-breadcrumbs {
  list-style: none;
  margin: 0;
  padding-left: 0;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
}
.breadcrumb .list-breadcrumbs .first .breadcrumb-link {
  color: #121112;
  border-bottom: 1px solid #121112;
  cursor: pointer;
}
.breadcrumb .list-breadcrumbs .first .breadcrumb-link:hover {
  color: #C00000;
  border-bottom: 3px solid #C00000;
}
.breadcrumb .list-breadcrumbs .second .breadcrumb-link {
  color: #5d5760;
  cursor: pointer;
}
.breadcrumb .list-breadcrumbs .second .breadcrumb-link:hover {
  color: #C00000;
  border-bottom: 3px solid #C00000;
}
.breadcrumb .list-breadcrumbs .breadcrumb-separator {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  font-weight: 500;
}
.breadcrumb .list-breadcrumbs .breadcrumb-active,
.breadcrumb .list-breadcrumbs \a0 a:hover,
.breadcrumb .list-breadcrumbs a:focus {
  margin-bottom: -1px;
}
.breadcrumb .ctti-more_horiz {
  margin-left: 0.5rem;
  margin-right: 0;
  font-weight: 500;
}
.breadcrumb .ctti-more_horiz:hover {
  margin-left: 0.5rem;
  margin-right: 0;
  font-weight: 500;
  color: #C00000;
  border-bottom: 2px solid #C00000;
  cursor: pointer;
}
.breadcrumb .ctti-no_link:hover {
  color: #C00000;
  cursor: pointer;
}
.ctti-tree ul,
.ctti-tree li {
  margin-top: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.ctti-tree .mat-nested-tree-node div[role="group"] {
  padding-left: 40px;
}
.ctti-tree .ctti-tree div[role="group"] > .mat-tree-node {
  padding-left: 40px;
}
.ctti-tree .mat-tree-node-leaf {
  padding-left: 40px;
}
.ctti-tree .mat-tree-node {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
}
.ctti-tree .mat-tree-node:hover {
  background-color: #f9f7f9;
}
.ctti-tree .mat-tree-node:focus-within {
  border-top: 1px solid #0080fa;
  border-bottom: 1px solid #0080fa;
  background-color: #f9f7f9;
}
.ctti-tree .mat-tree-node:active {
  color: #C00000;
  border-top: none;
  border-bottom: none;
  background-color: initial;
}
.ctti-tree .mat-tree-node .fuente {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
}
.ctti-tree .mat-tree-node .icono {
  padding-top: 0.5px;
  height: 42px;
  width: auto;
  padding-left: 0.5px;
}
.ctti-tree .mat-tree-node .nodo {
  padding-block: 8px;
}
.ctti-tree .mat-tree-node .mouse-pointer {
  cursor: pointer;
}
.ctti-tree .mat-tree-node .cdk-mouse-focused:active {
  background-color: #efbfbf !important;
}
.ctti-tree .mat-tree-node .cdk-mouse-focused:active mat-icon.icono:active {
  background-color: transparent !important;
}
.ctti-tree .disabled-leaf {
  color: #d8d4d9;
  pointer-events: none;
  border-top: none;
  border-bottom: none;
  background-color: initial;
}
.ctti-tree .node-selected {
  color: #C00000;
}
.ctti-tree .node-selected:hover {
  color: #121112;
}
.ctti-tree .node-selected:focus-within {
  color: #121112;
}
.ctti-tree .node-selected:active {
  color: #C00000;
}
.button-tree-icon {
  width: 24px !important;
  height: 24px !important;
  margin-left: 8px !important;
  margin-right: 8px !important;
}
.ctti-tree-invisible {
  display: none;
}
.ctti-hero {
  font-family: Open Sans;
  font-style: normal;
  font-size: 34px !important;
  line-height: 42px !important;
  font-weight: 700 !important;
  color: #121112;
  font-family: Open Sans, sans-serif;
}
@media (min-width: 768px) {
  .ctti-hero {
    font-size: 48px !important;
    line-height: 60px !important;
  }
}
.ctti-h1 {
  font-size: 1.875rem !important;
  line-height: 38px !important;
  font-weight: 600 !important;
  color: #121112;
  font-family: Open Sans, sans-serif;
}
@media (min-width: 768px) {
  .ctti-h1 {
    font-size: 2.25rem !important;
    line-height: 44px !important;
  }
}
.ctti-h2 {
  font-size: 1.625rem !important;
  line-height: 34px !important;
  font-weight: 600 !important;
  color: #121112;
  font-family: Open Sans, sans-serif;
}
@media (min-width: 768px) {
  .ctti-h2 {
    font-size: 2rem !important;
    line-height: 40px !important;
  }
}
.ctti-h3 {
  font-size: 1.5rem !important;
  line-height: 30px !important;
  color: #121112;
  font-family: Open Sans, sans-serif;
  font-weight: 600 !important;
}
@media (min-width: 768px) {
  .ctti-h3 {
    font-size: 1.75rem !important;
    line-height: 36px !important;
  }
}
.ctti-h4 {
  font-size: 1.375rem !important;
  line-height: 28px !important;
  font-weight: 600 !important;
  color: #121112;
  font-family: Open Sans, sans-serif;
}
@media (min-width: 768px) {
  .ctti-h4 {
    font-size: 1.5rem !important;
    line-height: 32px !important;
  }
}
.ctti-h5 {
  font-size: 1.25rem;
  line-height: 26px;
  font-weight: 600;
  color: #121112;
  font-family: Open Sans, sans-serif;
}
@media (min-width: 768px) {
  .ctti-h5 {
    font-size: 1.375rem;
    line-height: 28px;
  }
}
.ctti-h6 {
  font-size: 1.125rem;
  line-height: 24px;
  font-weight: 600;
  color: #121112;
  font-family: Open Sans, sans-serif;
}
@media (min-width: 768px) {
  .ctti-h6 {
    font-size: 1.25rem;
    line-height: 26px;
  }
}
.ctti-body {
  font-size: 1rem;
  line-height: 24px;
  font-family: Open Sans, sans-serif;
  color: #5d5760;
}
@media (min-width: 768px) {
  .ctti-body {
    font-size: 1.125rem;
    line-height: 26px;
  }
}
.ctti-body-bold {
  font-size: 1rem;
  line-height: 24px;
  font-family: Open Sans, sans-serif;
  color: #5d5760;
  font-weight: 700;
}
@media (min-width: 768px) {
  .ctti-body-bold {
    font-size: 1.125rem;
    line-height: 26px;
  }
}
.ctti-label {
  font-size: 0.875rem;
  line-height: 20px;
  font-family: Open Sans, sans-serif;
  color: #5d5760;
}
@media (min-width: 768px) {
  .ctti-label {
    font-size: 1rem;
    line-height: 26px;
  }
}
.ctti-label--black {
  color: #121112;
}
.ctti-label-bold {
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 20px;
  font-family: Open Sans, sans-serif;
  color: #5d5760;
}
@media (min-width: 768px) {
  .ctti-label-bold {
    font-size: 1rem;
    line-height: 26px;
  }
}
.ctti-label-bold--black {
  color: #121112;
}
.ctti-small {
  font-size: 0.75rem;
  line-height: 16px;
  font-family: Open Sans, sans-serif;
  color: #5d5760;
}
.ctti-small-bold {
  font-size: 0.75rem;
  line-height: 16px;
  font-family: Open Sans, sans-serif;
  color: #5d5760;
  font-weight: 700;
}
.ctti-link {
  font-size: 1rem;
  line-height: 2;
  font-family: Open Sans, sans-serif;
  color: #C00000;
  text-decoration: underline;
  text-underline-offset: 5px;
}
@media (min-width: 768px) {
  .ctti-link {
    font-size: 1.125rem;
    line-height: 1.5;
  }
}
.ctti-link:hover {
  color: #C00000;
  text-decoration: underline;
  text-decoration-thickness: 3px;
}
.ctti-link:hover {
  cursor: pointer;
}
.ctti-link-small {
  font-size: 0.75rem;
  line-height: 16px;
  color: #C00000;
  text-decoration: underline;
  text-underline-offset: 5px;
}
.ctti-link-small:hover {
  color: #C00000;
  text-decoration: underline;
  text-decoration-thickness: 3px;
}
.ctti-link-small:hover {
  cursor: pointer;
}
.ctti-link-label {
  font-size: 0.875rem;
  line-height: 20px;
  color: #C00000;
  text-decoration: underline;
  text-underline-offset: 5px;
  font-family: Open Sans, sans-serif;
}
.ctti-link-label:hover {
  color: #C00000;
  text-decoration: underline;
  text-decoration-thickness: 3px;
}
@media (min-width: 768px) {
  .ctti-link-label {
    font-size: 1rem;
    line-height: 26px;
  }
}
.ctti-link-label:hover {
  cursor: pointer;
}
.ctti-text-red-crimson-10 {
  color: #fbf1f1;
}
.ctti-text-red-crimson-20 {
  color: #efbfbf;
}
.ctti-text-red-crimson-30 {
  color: #e08081;
}
.ctti-text-red-crimson-40 {
  color: #c00000;
}
.ctti-text-red-crimson-50 {
  color: #c00000;
}
.ctti-text-red-crimson-60 {
  color: #C00000;
}
.ctti-text-red-crimson-70 {
  color: #600000;
}
.ctti-text-red-crimson-80 {
  color: #430000;
}
.ctti-text-red-crimson-rgb-10 {
  color: #fbf1f1;
}
.ctti-text-red-crimson-rgb-20 {
  color: #efbfbf;
}
.ctti-text-red-crimson-rgb-30 {
  color: #e08081;
}
.ctti-text-red-crimson-rgb-40 {
  color: #c00000;
}
.ctti-text-red-crimson-rgb-50 {
  color: #c00000;
}
.ctti-text-red-crimson-rgb-60 {
  color: #C00000;
}
.ctti-text-red-crimson-rgb-70 {
  color: #600000;
}
.ctti-text-red-crimson-rgb-80 {
  color: #430000;
}
.ctti-text-magenta-10 {
  color: #fcecfa;
}
.ctti-text-magenta-20 {
  color: #f8d3f3;
}
.ctti-text-magenta-30 {
  color: #f49ae7;
}
.ctti-text-magenta-40 {
  color: #e972d8;
}
.ctti-text-magenta-50 {
  color: #e545cf;
}
.ctti-text-magenta-60 {
  color: #cb20b2;
}
.ctti-text-magenta-70 {
  color: #8f167d;
}
.ctti-text-magenta-80 {
  color: #3d0b36;
}
.ctti-text-magenta-rgb-10 {
  color: #fcecfa;
}
.ctti-text-magenta-rgb-20 {
  color: #f8d3f3;
}
.ctti-text-magenta-rgb-30 {
  color: #e09ae7;
}
.ctti-text-magenta-rgb-40 {
  color: #e972d8;
}
.ctti-text-magenta-rgb-50 {
  color: #e545cf;
}
.ctti-text-magenta-rgb-60 {
  color: #cb20b2;
}
.ctti-text-magenta-rgb-70 {
  color: #8f167d;
}
.ctti-text-magenta-rgb-80 {
  color: #3d0b36;
}
.ctti-text-white,
.ctti-text-white-rgb {
  color: #fff;
}
.ctti-text-black,
.ctti-text-black-rgb {
  color: #000;
}
.ctti-text-turquoise-10 {
  color: #e2f6f4;
}
.ctti-text-turquoise-20 {
  color: #baf0ea;
}
.ctti-text-turquoise-30 {
  color: #89e9de;
}
.ctti-text-turquoise-40 {
  color: #3acebd;
}
.ctti-text-turquoise-50 {
  color: #00a390;
}
.ctti-text-turquoise-60 {
  color: #007567;
}
.ctti-text-turquoise-70 {
  color: #00473f;
}
.ctti-text-turquoise-80 {
  color: #002824;
}
.ctti-text-turquoise-rgb-10 {
  color: #e2f6f4;
}
.ctti-text-turquoise-rgb-20 {
  color: #baf6ea;
}
.ctti-text-turquoise-rgb-30 {
  color: #89e9de;
}
.ctti-text-turquoise-rgb-40 {
  color: #3acebd;
}
.ctti-text-turquoise-rgb-50 {
  color: #00a390;
}
.ctti-text-turquoise-rgb-60 {
  color: #007567;
}
.ctti-text-turquoise-rgb-70 {
  color: #00473f;
}
.ctti-text-turquoise-rgb-80 {
  color: #002824;
}
.ctti-text-blue-10 {
  color: #e3f1ff;
}
.ctti-text-blue-20 {
  color: #c6e3ff;
}
.ctti-text-blue-30 {
  color: #a1d1ff;
}
.ctti-text-blue-40 {
  color: #4aa6ff;
}
.ctti-text-blue-50 {
  color: #0080fa;
}
.ctti-text-blue-60 {
  color: #005eb8;
}
.ctti-text-blue-70 {
  color: #003a71;
}
.ctti-text-blue-80 {
  color: #002140;
}
.ctti-text-blue-rgb-10 {
  color: #e3f1ff;
}
.ctti-text-blue-rgb-20 {
  color: #c6e3ff;
}
.ctti-text-blue-rgb-30 {
  color: #a1d1ff;
}
.ctti-text-blue-rgb-40 {
  color: #4aa6ff;
}
.ctti-text-blue-rgb-50 {
  color: #0080fa;
}
.ctti-text-blue-rgb-60 {
  color: #005eb8;
}
.ctti-text-blue-rgb-70 {
  color: #003a71;
}
.ctti-text-blue-rgb-80 {
  color: #002140;
}
.ctti-text-neutral-10 {
  color: #f9f7f9;
}
.ctti-text-neutral-20 {
  color: #eae6eb;
}
.ctti-text-neutral-30 {
  color: #d8d4d9;
}
.ctti-text-neutral-40 {
  color: #bcb9bd;
}
.ctti-text-neutral-50 {
  color: #959198;
}
.ctti-text-neutral-60 {
  color: #5d5760;
}
.ctti-text-neutral-70 {
  color: #343034;
}
.ctti-text-neutral-80,
.ctti-tabs.mat-tab-group .mat-tab-label,
.ctti-tabs-container.mat-tab-group .mat-tab-label {
  color: #121112;
}
.ctti-text-neutral-rgb-10 {
  color: #f9f7f9;
}
.ctti-text-neutral-rgb-20 {
  color: #eae6eb;
}
.ctti-text-neutral-rgb-30 {
  color: #d8d4d9;
}
.ctti-text-neutral-rgb-40 {
  color: #bcb9bd;
}
.ctti-text-neutral-rgb-50 {
  color: #959198;
}
.ctti-text-neutral-rgb-60 {
  color: #5d5760;
}
.ctti-text-neutral-rgb-70 {
  color: #343034;
}
.ctti-text-neutral-rgb-80 {
  color: #121112;
}
.ctti-text-green-10 {
  color: #eef8e2;
}
.ctti-text-green-20 {
  color: #cceba8;
}
.ctti-text-green-30 {
  color: #afe076;
}
.ctti-text-green-40 {
  color: #91d444;
}
.ctti-text-green-50 {
  color: #68a225;
}
.ctti-text-green-60 {
  color: #458200;
}
.ctti-text-green-70 {
  color: #2c4510;
}
.ctti-text-green-80 {
  color: #1b2b0c;
}
.ctti-text-green-rgb-10 {
  color: #eef8e2;
}
.ctti-text-green-rgb-20 {
  color: #cceba8;
}
.ctti-text-green-rgb-30 {
  color: #afe076;
}
.ctti-text-green-rgb-40 {
  color: #91d444;
}
.ctti-text-green-rgb-50 {
  color: #68a225;
}
.ctti-text-green-rgb-60 {
  color: #458200;
}
.ctti-text-green-rgb-70 {
  color: #2c4510;
}
.ctti-text-green-rgb-80 {
  color: #1b2b0c;
}
.ctti-text-orange-10 {
  color: #fff2eb;
}
.ctti-text-orange-20 {
  color: #fee3d1;
}
.ctti-text-orange-30 {
  color: #f9cdb4;
}
.ctti-text-orange-40 {
  color: #f6ae84;
}
.ctti-text-orange-50 {
  color: #ef6f25;
}
.ctti-text-orange-60 {
  color: #c04700;
}
.ctti-text-orange-70 {
  color: #7e3409;
}
.ctti-text-orange-80 {
  color: #301404;
}
.ctti-text-orange-rgb-10 {
  color: #fff2eb;
}
.ctti-text-orange-rgb-20 {
  color: #fee3d1;
}
.ctti-text-orange-rgb-30 {
  color: #f9cdb4;
}
.ctti-text-orange-rgb-40 {
  color: #f6ae84;
}
.ctti-text-orange-rgb-50 {
  color: #ef6f25;
}
.ctti-text-orange-rgb-60 {
  color: #c04700;
}
.ctti-text-orange-rgb-70 {
  color: #7e3409;
}
.ctti-text-orange-rgb-80 {
  color: #301404;
}
.ctti-text-red-10 {
  color: #fff0f0;
}
.ctti-text-red-20 {
  color: #ffd0d1;
}
.ctti-text-red-30 {
  color: #ffa7a9;
}
.ctti-text-red-40 {
  color: #ff7f80;
}
.ctti-text-red-50 {
  color: #ff2d30;
}
.ctti-text-red-60 {
  color: #ea0003;
}
.ctti-text-red-70 {
  color: #a20002;
}
.ctti-text-red-80 {
  color: #560001;
}
.ctti-text-red-rgb-10 {
  color: #fff0f0;
}
.ctti-text-red-rgb-20 {
  color: #ffd0d1;
}
.ctti-text-red-rgb-30 {
  color: #ffa7a9;
}
.ctti-text-red-rgb-40 {
  color: #ff7f80;
}
.ctti-text-red-rgb-50 {
  color: #ff2d30;
}
.ctti-text-red-rgb-60 {
  color: #ea0003;
}
.ctti-text-red-rgb-70 {
  color: #a20002;
}
.ctti-text-red-rgb-80 {
  color: #560001;
}
.ctti-text-yellow-10 {
  color: #fef8d7;
}
.ctti-text-yellow-20 {
  color: #fdefa7;
}
.ctti-text-yellow-30 {
  color: #fde981;
}
.ctti-text-yellow-40 {
  color: #fce156;
}
.ctti-text-yellow-50 {
  color: #fad40f;
}
.ctti-text-yellow-60 {
  color: #cdac04;
}
.ctti-text-yellow-70 {
  color: #8a7404;
}
.ctti-text-yellow-80 {
  color: #38310a;
}
.ctti-text-yellow-rgb-10 {
  color: #fef8d7;
}
.ctti-text-yellow-rgb-20 {
  color: #fdefa7;
}
.ctti-text-yellow-rgb-30 {
  color: #fde981;
}
.ctti-text-yellow-rgb-40 {
  color: #fce156;
}
.ctti-text-yellow-rgb-50 {
  color: #fad40f;
}
.ctti-text-yellow-rgb-60 {
  color: #cdac04;
}
.ctti-text-yellow-rgb-70 {
  color: #8a7404;
}
.ctti-text-yellow-rgb-80 {
  color: #38310a;
}
.ctti-bg-red-crimson-10 {
  background-color: #fbf1f1;
}
.ctti-bg-red-crimson-20 {
  background-color: #efbfbf;
}
.ctti-bg-red-crimson-30 {
  background-color: #e08081;
}
.ctti-bg-red-crimson-40 {
  background-color: #c00000;
}
.ctti-bg-red-crimson-50 {
  background-color: #c00000;
}
.ctti-bg-red-crimson-60 {
  background-color: #C00000;
}
.ctti-bg-red-crimson-70 {
  background-color: #600000;
}
.ctti-bg-red-crimson-80 {
  background-color: #430000;
}
.ctti-bg-red-crimson-rgb-10 {
  background-color: #fbf1f1;
}
.ctti-bg-red-crimson-rgb-20 {
  background-color: #efbfbf;
}
.ctti-bg-red-crimson-rgb-30 {
  background-color: #e08081;
}
.ctti-bg-red-crimson-rgb-40 {
  background-color: #c00000;
}
.ctti-bg-red-crimson-rgb-50 {
  background-color: #c00000;
}
.ctti-bg-red-crimson-rgb-60 {
  background-color: #C00000;
}
.ctti-bg-red-crimson-rgb-70 {
  background-color: #600000;
}
.ctti-bg-red-crimson-rgb-80 {
  background-color: #430000;
}
.ctti-bg-magenta-10 {
  background-color: #fcecfa;
}
.ctti-bg-magenta-20 {
  background-color: #f8d3f3;
}
.ctti-bg-magenta-30 {
  background-color: #f49ae7;
}
.ctti-bg-magenta-40 {
  background-color: #e972d8;
}
.ctti-bg-magenta-50 {
  background-color: #e545cf;
}
.ctti-bg-magenta-60 {
  background-color: #cb20b2;
}
.ctti-bg-magenta-70 {
  background-color: #8f167d;
}
.ctti-bg-magenta-80 {
  background-color: #3d0b36;
}
.ctti-bg-magenta-rgb-10 {
  background-color: #fcecfa;
}
.ctti-bg-magenta-rgb-20 {
  background-color: #f8d3f3;
}
.ctti-bg-magenta-rgb-30 {
  background-color: #e09ae7;
}
.ctti-bg-magenta-rgb-40 {
  background-color: #e972d8;
}
.ctti-bg-magenta-rgb-50 {
  background-color: #e545cf;
}
.ctti-bg-magenta-rgb-60 {
  background-color: #cb20b2;
}
.ctti-bg-magenta-rgb-70 {
  background-color: #8f167d;
}
.ctti-bg-magenta-rgb-80 {
  background-color: #3d0b36;
}
.ctti-bg-white,
.ctti-bg-white-rgb {
  background-color: #fff;
}
.ctti-bg-black,
.ctti-bg-black-rgb {
  background-color: #000;
}
.ctti-bg-turquoise-10 {
  background-color: #e2f6f4;
}
.ctti-bg-turquoise-20 {
  background-color: #baf0ea;
}
.ctti-bg-turquoise-30 {
  background-color: #89e9de;
}
.ctti-bg-turquoise-40 {
  background-color: #3acebd;
}
.ctti-bg-turquoise-50 {
  background-color: #00a390;
}
.ctti-bg-turquoise-60 {
  background-color: #007567;
}
.ctti-bg-turquoise-70 {
  background-color: #00473f;
}
.ctti-bg-turquoise-80 {
  background-color: #002824;
}
.ctti-bg-turquoise-rgb-10 {
  background-color: #e2f6f4;
}
.ctti-bg-turquoise-rgb-20 {
  background-color: #baf6ea;
}
.ctti-bg-turquoise-rgb-30 {
  background-color: #89e9de;
}
.ctti-bg-turquoise-rgb-40 {
  background-color: #3acebd;
}
.ctti-bg-turquoise-rgb-50 {
  background-color: #00a390;
}
.ctti-bg-turquoise-rgb-60 {
  background-color: #007567;
}
.ctti-bg-turquoise-rgb-70 {
  background-color: #00473f;
}
.ctti-bg-turquoise-rgb-80 {
  background-color: #002824;
}
.ctti-bg-blue-10 {
  background-color: #e3f1ff;
}
.ctti-bg-blue-20 {
  background-color: #c6e3ff;
}
.ctti-bg-blue-30 {
  background-color: #a1d1ff;
}
.ctti-bg-blue-40 {
  background-color: #4aa6ff;
}
.ctti-bg-blue-50 {
  background-color: #0080fa;
}
.ctti-bg-blue-60 {
  background-color: #005eb8;
}
.ctti-bg-blue-70 {
  background-color: #003a71;
}
.ctti-bg-blue-80 {
  background-color: #002140;
}
.ctti-bg-blue-rgb-10 {
  background-color: #e3f1ff;
}
.ctti-bg-blue-rgb-20 {
  background-color: #c6e3ff;
}
.ctti-bg-blue-rgb-30 {
  background-color: #a1d1ff;
}
.ctti-bg-blue-rgb-40 {
  background-color: #4aa6ff;
}
.ctti-bg-blue-rgb-50 {
  background-color: #0080fa;
}
.ctti-bg-blue-rgb-60 {
  background-color: #005eb8;
}
.ctti-bg-blue-rgb-70 {
  background-color: #003a71;
}
.ctti-bg-blue-rgb-80 {
  background-color: #002140;
}
.ctti-bg-neutral-10 {
  background-color: #f9f7f9;
}
.ctti-bg-neutral-20 {
  background-color: #eae6eb;
}
.ctti-bg-neutral-30 {
  background-color: #d8d4d9;
}
.ctti-bg-neutral-40 {
  background-color: #bcb9bd;
}
.ctti-bg-neutral-50 {
  background-color: #959198;
}
.ctti-bg-neutral-60 {
  background-color: #5d5760;
}
.ctti-bg-neutral-70 {
  background-color: #343034;
}
.ctti-bg-neutral-80 {
  background-color: #121112;
}
.ctti-bg-neutral-rgb-10 {
  background-color: #f9f7f9;
}
.ctti-bg-neutral-rgb-20 {
  background-color: #eae6eb;
}
.ctti-bg-neutral-rgb-30 {
  background-color: #d8d4d9;
}
.ctti-bg-neutral-rgb-40 {
  background-color: #bcb9bd;
}
.ctti-bg-neutral-rgb-50 {
  background-color: #959198;
}
.ctti-bg-neutral-rgb-60 {
  background-color: #5d5760;
}
.ctti-bg-neutral-rgb-70 {
  background-color: #343034;
}
.ctti-bg-neutral-rgb-80 {
  background-color: #121112;
}
.ctti-bg-green-10 {
  background-color: #eef8e2;
}
.ctti-bg-green-20 {
  background-color: #cceba8;
}
.ctti-bg-green-30 {
  background-color: #afe076;
}
.ctti-bg-green-40 {
  background-color: #91d444;
}
.ctti-bg-green-50 {
  background-color: #68a225;
}
.ctti-bg-green-60 {
  background-color: #458200;
}
.ctti-bg-green-70 {
  background-color: #2c4510;
}
.ctti-bg-green-80 {
  background-color: #1b2b0c;
}
.ctti-bg-green-rgb-10 {
  background-color: #eef8e2;
}
.ctti-bg-green-rgb-20 {
  background-color: #cceba8;
}
.ctti-bg-green-rgb-30 {
  background-color: #afe076;
}
.ctti-bg-green-rgb-40 {
  background-color: #91d444;
}
.ctti-bg-green-rgb-50 {
  background-color: #68a225;
}
.ctti-bg-green-rgb-60 {
  background-color: #458200;
}
.ctti-bg-green-rgb-70 {
  background-color: #2c4510;
}
.ctti-bg-green-rgb-80 {
  background-color: #1b2b0c;
}
.ctti-bg-orange-10 {
  background-color: #fff2eb;
}
.ctti-bg-orange-20 {
  background-color: #fee3d1;
}
.ctti-bg-orange-30 {
  background-color: #f9cdb4;
}
.ctti-bg-orange-40 {
  background-color: #f6ae84;
}
.ctti-bg-orange-50 {
  background-color: #ef6f25;
}
.ctti-bg-orange-60 {
  background-color: #c04700;
}
.ctti-bg-orange-70 {
  background-color: #7e3409;
}
.ctti-bg-orange-80 {
  background-color: #301404;
}
.ctti-bg-orange-rgb-10 {
  background-color: #fff2eb;
}
.ctti-bg-orange-rgb-20 {
  background-color: #fee3d1;
}
.ctti-bg-orange-rgb-30 {
  background-color: #f9cdb4;
}
.ctti-bg-orange-rgb-40 {
  background-color: #f6ae84;
}
.ctti-bg-orange-rgb-50 {
  background-color: #ef6f25;
}
.ctti-bg-orange-rgb-60 {
  background-color: #c04700;
}
.ctti-bg-orange-rgb-70 {
  background-color: #7e3409;
}
.ctti-bg-orange-rgb-80 {
  background-color: #301404;
}
.ctti-bg-red-10 {
  background-color: #fff0f0;
}
.ctti-bg-red-20 {
  background-color: #ffd0d1;
}
.ctti-bg-red-30 {
  background-color: #ffa7a9;
}
.ctti-bg-red-40 {
  background-color: #ff7f80;
}
.ctti-bg-red-50 {
  background-color: #ff2d30;
}
.ctti-bg-red-60 {
  background-color: #ea0003;
}
.ctti-bg-red-70 {
  background-color: #a20002;
}
.ctti-bg-red-80 {
  background-color: #560001;
}
.ctti-bg-red-rgb-10 {
  background-color: #fff0f0;
}
.ctti-bg-red-rgb-20 {
  background-color: #ffd0d1;
}
.ctti-bg-red-rgb-30 {
  background-color: #ffa7a9;
}
.ctti-bg-red-rgb-40 {
  background-color: #ff7f80;
}
.ctti-bg-red-rgb-50 {
  background-color: #ff2d30;
}
.ctti-bg-red-rgb-60 {
  background-color: #ea0003;
}
.ctti-bg-red-rgb-70 {
  background-color: #a20002;
}
.ctti-bg-red-rgb-80 {
  background-color: #560001;
}
.ctti-bg-yellow-10 {
  background-color: #fef8d7;
}
.ctti-bg-yellow-20 {
  background-color: #fdefa7;
}
.ctti-bg-yellow-30 {
  background-color: #fde981;
}
.ctti-bg-yellow-40 {
  background-color: #fce156;
}
.ctti-bg-yellow-50 {
  background-color: #fad40f;
}
.ctti-bg-yellow-60 {
  background-color: #cdac04;
}
.ctti-bg-yellow-70 {
  background-color: #8a7404;
}
.ctti-bg-yellow-80 {
  background-color: #38310a;
}
.ctti-bg-yellow-rgb-10 {
  background-color: #fef8d7;
}
.ctti-bg-yellow-rgb-20 {
  background-color: #fdefa7;
}
.ctti-bg-yellow-rgb-30 {
  background-color: #fde981;
}
.ctti-bg-yellow-rgb-40 {
  background-color: #fce156;
}
.ctti-bg-yellow-rgb-50 {
  background-color: #fad40f;
}
.ctti-bg-yellow-rgb-60 {
  background-color: #cdac04;
}
.ctti-bg-yellow-rgb-70 {
  background-color: #8a7404;
}
.ctti-bg-yellow-rgb-80 {
  background-color: #38310a;
}
.ctti-w100 {
  font-weight: 100;
}
.ctti-w200 {
  font-weight: 200;
}
.ctti-w300 {
  font-weight: 300;
}
.ctti-w400 {
  font-weight: 400;
}
.ctti-w500 {
  font-weight: 500;
}
.ctti-w600 {
  font-weight: 600;
}
.ctti-w700 {
  font-weight: 700;
}
.ctti-w800 {
  font-weight: 800;
}
.ctti-w900 {
  font-weight: 900;
}
.ctti-w-56 {
  width: 56px;
}
.ctti-w-280 {
  width: 280px;
}
.md-18 {
  font-size: 18px;
}
.md-20 {
  font-size: 20px;
}
.md-24 {
  font-size: 24px;
}
.md-32 {
  font-size: 32px;
}
.md-36 {
  font-size: 36px;
}
.md-48 {
  font-size: 48px;
}
.line-height-1 {
  line-height: 1;
}
.ctti-invalid-field {
  box-shadow: 0 0 0 1px #ea0003 !important;
  border-color: #ea0003 !important;
}
}