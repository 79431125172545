@use "@angular/material" as mat;
@include mat.core();
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");
@import "bootstrap/scss/bootstrap.scss";
@import "styles/ctti.scss";

html,
body {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: Open Sans !important;
  display: flex;
  flex-direction: column;
}
p {
  font-size: 1rem;
  color: #333;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 0;
}
main {
  flex: 1;
  min-height: calc(100vh - 160px);
}
.dflex {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.mat-mdc-select-trigger {
  font-family: Open Sans !important;
  font-size: 1rem;
  color: #121212;
}

.order {
  border-bottom: 1px solid #121212;
  padding-bottom: 15px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.invs{
  color: #FFF !important;
  font-size: 1px;
}
th.invs{
  height: 0.1px;
}

.btn-accions{
  color: #c00000;
  border-color: transparent;
  background-color: transparent;
  height: 24px;
  display: flex;
  justify-content: center;
}

.textarea {
  width: 100%;
  min-height: 200px;
  border: 1px solid black;
  border-radius: 8px;
  padding: 10px;
  font-size: 18px;
}

span.negreta {
  font-weight: bold;
  color: #333333;
}

span.copiat {
  color: #c00000;
  font-size: 14px;
}

.codiCopiar {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 24px;
  border: 1px solid #afafaf;
  background-color: #f5f5f5;
  p,
  label {
    margin: 0 !important;
  }
}

.info-user {
  span {
    color: #0080fa;
  }
  p {
    span {
      font-weight: bold;
      color: #333333;
    }
  }
  display: flex;
  flex-direction: row;
  gap: 6px;
}

.radio-buttons {
  display: flex;
  gap: 8px;
}

.radio-buttons label {
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  font-weight: 500;
  color: #333;
}

.radio-buttons input[type="radio"] {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #121212;
  border-radius: 50%;
  display: grid;
  place-content: center;
  transition: all 0.2s ease-in-out;
}

.radio-buttons input[type="radio"]::before {
  content: "";
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #121212;
  transform: scale(0);
  transition: transform 0.2s ease-in-out;
}

.radio-buttons input[type="radio"]:checked::before {
  transform: scale(1);
}

.divider {
  display: block;
  margin: 0;
  border-top-width: 1px;
  border-top-style: solid;
  border-color: #9c9c9c;
  width: 100%;
}
.mat-mdc-select-arrow svg {
  visibility: hidden !important;
}
.mat-mdc-menu-content {
  padding: 0 !important;
}
.mat-pseudo-checkbox-full {
  border-color: #333333 !important;
  color: #333333 !important;
}
div.mat-mdc-select-panel {
  background-color: white !important;
  margin-top: 15px;
  margin-left: -15px;
  color: #121112;
  border: 1px solid #121112;
}
.mdc-label {
  padding: 0 !important;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  color: #333333;
}
.mat-mdc-select {
  display: flex !important;
  line-height: 24px !important;
  max-width: 475px !important;
}
.ctti-input-text {
  outline: none;
  width: 100%;
  height: 48px;
  display: flex;
  background-color: #fff;
  border-style: solid;
  border-width: 1px;
  border-color: #121112;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 1rem;
  line-height: 24px;
  font-weight: 600;
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;
}
.ctti-input-text::placeholder {
  font-weight: 400;
  color: #5d5760;
  opacity: 1;
}
.ctti-button-inv {
  background: transparent;
  border: none;
  color: #c00000;
  border-radius: 4px;
  padding: 0;
}
.ctti-button-trans {
  background: transparent;
  border: 1px solid #c00000;
  border-radius: 4px;
  color: #c00000;
}
.ctti-button-filled {
  background: #c00000;
  border: 1px solid #c00000;
  color: #fff;
}
.ctti-button {
  font-size: 1rem;
  line-height: 24px;
  font-weight: 600;
  appearance: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 12px 12px;
  min-height: 48px;
  border-radius: 4px;
  box-sizing: border-box;
  position: relative;
  height: fit-content;
  cursor: pointer;
  min-width: 108px;
}
.ctti-dropdown {
  font-size: 1rem;
  font-family: "Open Sans" !important;
  margin-bottom: 0.5rem;
  width: 100%;
  height: 48px;
  display: flex;
  background-color: #fff;
  border-style: solid;
  border-width: 1px;
  border-color: #121112;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  line-height: 24px;
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;
}

mat-pseudo-checkbox {
  background: #fff !important;
  border: none !important;
}

.desplegable {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
}

.bola{
  width: 42px;
  height: 42px;
  padding: 8px;
  border-radius: 100px;
  border: 1.5px solid var(--secundaries-black-neutral-80, #121112);
  background: var(--Primaries-White, #FFF);
}
.validated{
  background: #458200;
  color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none !important;
}

.ctti-modal {
  width: 991px;
  position: relative;
  max-height: 100vh;
  overflow-y: auto;
  padding: 25px;
  z-index: 10;
  text-align: center;
  .center-icon {
    font-size: 42px;
    width: 42px;
    height: 42px;
    color: #c00000;
    margin-bottom: 30px;
  }
  .tar {
    color: #ef6f25 !important;
  }
  .botones {
    display: flex;
    flex-direction: row;
    justify-content: right;
    gap: 12px;
    width: 100%;
  }
  .search-bar {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
  }
  .cross {
    width: 30px;
    margin-left: auto;
    cursor: pointer;
  }
}
.mdc-checkbox {
  margin: 10px;
}

.red {
  color: #c00000;
}
.white {
  color: #ffffff;
}
.disabled {
  background: #666666 !important;
}

.title1,
.title2,
.title3,
.title4,
.title5 {
  color: #121112;
  font-weight: 600;
  line-height: 26px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.title1 {
  font-size: 48px;
}
.title2 {
  font-size: 36px;
}
.title3 {
  font-size: 32px;
}
.title4 {
  font-size: 28px;
}
.title5 {
  font-size: 22px;
}
.mdc-tooltip {
  background-color: #c00000 !important;
  border-radius: 8px;
  color: white !important;
  font-size: 14px;
  z-index: 10 !important;
}
.mat-tooltip-panel {
  overflow: visible !important;
  z-index: 10 !important;
}
.mat-icon {
  font-family: "Material Icons Outlined" !important;
}

.pagination-controls {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  .items-page {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .page-number {
    display: flex;
    flex-direction: row;
    justify-content: right;
    gap: 16px;
    align-items: center;
    button {
      background: transparent;
      border-radius: 4px;
      border: 1px solid black;
      padding: 10px;
      padding: 0;
      height: 40px;
      width: 40px;
      color: #333333;
      mat-icon {
        margin-top: 4px;
      }
    }
  }
  select {
    background: transparent;
    border: none;
    outline: none;
    border-bottom: 2px solid black;
    font-family: open Sans;
  }
}

.habitual {
  border-radius: 20px;
  padding: 10px;
  height: 42px;
  margin-right: 16px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #121112;
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: center;
  align-items: center;
  &.selected {
    color: #fff !important;
    background-color: #121112 !important;
  }
}

p.paginacio {
  font-size: 16px;
  color: #333333;
  margin: 0;
}

.corazon {
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-top: -4px;
}

.habituals-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin-top: 20px;
  margin-bottom: 20px;
  .habitual-item {
    border: 1px solid var(--Primaries-Medium-grey, #333);
    background: var(--Primaries-White, #fff);
    display: flex;
    width: 100%;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    text-align: left !important;
  }
}

.actions-habituals{
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  gap: 16px;
  font-size: 25px;
  width: 100%;
  padding-left: 24px;
  p {
    cursor: pointer;
    &:active {
      border-radius: 8px;
      background-color: #c7c7c7;
    }
  }
}
.actions-habituals, .accions, .actions {
  button {
    border-color: transparent;
    cursor: pointer;
    background-color: #fff;
    &:active {
      border-radius: 8px;
      background-color: #c7c7c7;
    }
  }
}
.actions {
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: flex-start;
  gap: 16px;
  font-size: 25px;
  width: 100%;
  height: 132px;
  padding-left: 24px;
}

.placeholder {
  color: var(--Primaries-Medium-grey, #333);
}
.enDada {
  box-shadow: 0 0 0 1px #121112;
  border-color: #121112;
  color: #121112;
  border: 1px solid #121112 !important;
  font-weight: 600 !important;
}
input[type="date"],
.ctti-dropdown {
  font-weight: 400;
}
input[type="text"]:not(:placeholder-shown) {
  border: 2px solid #121112;
}
.dataTaulaHabitual {
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: left;
  text-align: left;
  label {
    font-size: 14px;
  }
}
.grid-2 {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  row-gap: 16px;
  column-gap: 32px;
  .col {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    label {
      color: var(--Extended-palette-Neutral---50, #666);
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }
}

.span2 {
  grid-column: span 2;
}

.text-left {
  text-align: left;
}

.more {
  background-color: #fff !important;
  margin: 0 !important;
  padding: 0 !important;
  li {
    background-color: #fff;
    padding: 10px;
    padding-bottom: 5px;
    margin: 5px;
    padding-top: 5px;
    color: #c00000;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    gap: 8px;
    cursor: pointer;
    &:active,
    &:hover {
      border-radius: 4px;
      background-color: #c7c7c7;
    }
    p {
      color: #c00000;
      font-weight: 500;
    }
  }
}
.codi {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 24px;
  padding-top: 24px;
  padding-right: 24px;
  div {
    max-width: 158px;
  }
}

.mat-mdc-menu-panel{
  border-radius: 8px !important;
  margin-top: 14.5px;
}

.carrega-tipus {
  color: #666;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
}

.contextList {
  list-style: none;
  position: absolute;
  top: 100%;
  left: 0;
  max-height: 400px;
  width: 100%;
  z-index: 10;
  overflow-y: auto;
  background-color: white;
  border-radius: 8px;
  border: 1px solid #a1a1a1;
  li {
    height: 38px;
    font-size: 16px;
    padding: 8px;
    border-top: none;
    z-index: 10;
    &:hover {
      background-color: #e2e2e2;
    }
  }
}

.dc_creat,
.dc_no_creat,
.dc_modificacio {
  border-radius: 100%;
  background: #fff;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  mat-icon {
    margin-top: 3px;
    font-size: 20px;
  }
}
.dc_creat {
  mat-icon {
    color: #458200;
  }
}
.dc_no_creat {
  mat-icon {
    color: #e20000;
  }
}
.dc_modificacio {
  mat-icon {
    color: #ef6f25;
  }
}

.esborrany,
.planificat,
.realizat,
.no_realizat,
.rebutjat,
.reassignat,
.cancelat {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding: 6px 10px;
  text-align: center;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  width: 159px;
  height: 32px;
  text-transform: capitalize;
}
.esborrany {
  background-color: #666666;
  color: white;
}
.planificat {
  background-color: #f6ae84;
  color: #121212;
}
.realizat {
  background-color: #4d7a1b;
  color: white;
}
.no_realizat {
  background-color: #fad40f;
  color: #121212;
}
.cancelat {
  background-color: #ff2d30;
  color: white;
}
.rebutjat {
  color: white;
  background-color: #a00000;
}
.mobile {
  display: none;
}

@media (max-width: 1199px) {
  .grid-2 {
    grid-template-columns: 1fr !important;
  }
  .ctti-modal {
    max-width: 991px;
    min-width: 100%;
    width: auto !important;
  }
}

@media (max-width: 767px) {
  .esborrany,
  .planificat,
  .realizat,
  .no_realizat,
  .rebutjat,
  .cancelat {
    font-size: 12px;
    width: 124px;
    padding: 8px;
  }
  .actions {
    padding-left: 0;
  }
  .codi {
    height: 50px;
    max-width: none;
    width: 100%;
    padding: 0;
    margin-bottom: 16px;
    .codi-estat {
      max-width: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      h4 {
        margin: 0 !important;
      }
    }
  }
  .mb {
    margin-bottom: 32px;
    min-height: 75px;
  }
  .input {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .order {
    border-bottom: none;
  }
  .botones {
    flex-direction: column !important;
    margin-bottom: 30px;
    button {
      width: 100%;
    }
  }
  .pagination-controls {
    justify-content: center;
    #itemsPerPage,
    .page-number > select {
      padding: 8px;
    }
    .page-number > select {
      padding-left: 20px !important;
    }
    .page-number > button > mat-icon {
      margin-top: 3px;
    }
  }
  .ctti-modal {
    height: auto;
  }
  .habituals-modal {
    min-height: 650px !important;
  }
  .noMobile {
    display: none;
  }
  .mobile {
    display: block;
    height: 20px;
  }

  .title1 {
    font-size: 40px;
  }
  .title2 {
    font-size: 32px;
  }
  .title3 {
    font-size: 28px;
  }
  .title4 {
    font-size: 22px;
  }
  .title5 {
    font-size: 18px;
  }
}
